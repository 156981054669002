import { NotificationInterface } from "@relay/interfaces"
import { apiClientWithAuth, apiEndpoints } from ".."

export type PostNotificationRequest = Omit<
  NotificationInterface,
  "id" | "recipient"
>

export const postNotificationsBatch = async (
  notifications: PostNotificationRequest[],
) => {
  const res = await apiClientWithAuth.post(
    `${apiEndpoints.notifications}/batch`,
    { notifications },
  )
  return res.data
}

export const postNotification = async (
  notification: PostNotificationRequest | any,
): Promise<string> => {
  const res = await apiClientWithAuth.post(
    apiEndpoints.notifications,
    notification,
  )
  const notificationId = res.data
  return notificationId
}

export const updateNotification = async (
  notificationId: string,
  updates: Partial<NotificationInterface>,
): Promise<any> => {
  const res = await apiClientWithAuth.put(
    `${apiEndpoints.notifications}/${notificationId}`,
    updates,
  )

  return res.data
}

export const updateMassNotification = async (
  notificationId: string,
  updates: Partial<NotificationInterface>,
): Promise<any> => {
  const res = await apiClientWithAuth.put(
    `${apiEndpoints.notifications}/mass/${notificationId}`,
    updates,
  )

  return res.data
}

interface FetchNotificationOptions {
  limit?: number
  viewed?: boolean
}
export const fetchNotifications = async ({
  limit,
  viewed,
}: FetchNotificationOptions): Promise<NotificationInterface[]> => {
  const url = `${apiEndpoints.notifications}/all`

  const res = await apiClientWithAuth.get(url, { params: { limit, viewed } })
  return res.data
}

export const fetchNotificationsByGroup = async (
  groupId: string,
): Promise<NotificationInterface[]> => {
  const url = `${apiEndpoints.notifications}/group/${groupId}`
  const res = await apiClientWithAuth.get(url)
  return res.data
}

export const fetchNotificationsByCohort = async (
  cohortId: string,
): Promise<NotificationInterface[]> => {
  const url = `${apiEndpoints.notifications}/cohort/${cohortId}`
  const res = await apiClientWithAuth.get(url)
  return res.data
}

export const deleteNotification = async (id: string) => {
  const res = await apiClientWithAuth.delete(
    `${apiEndpoints.notifications}/mass/${id}`,
  )
  return res.data
}
