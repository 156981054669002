import { AnalysisInterface, JobInterface } from "@relay/interfaces"
import { apiClientWithAuth, apiEndpoints } from ".."

export const reportJob = async (jobId: string, reason: string) => {
  if (!jobId) return // gracefully fail - new data should have job.id but if older data is used this is in place to prevent explosions.
  const res = await apiClientWithAuth.post(`${apiEndpoints.jobs}/report`, {
    reason,
    jobId,
  })
  return res.data
}

export const postToJobMarketAnalysis = async (
  jobLeadId: string,
): Promise<AnalysisInterface> => {
  const res = await apiClientWithAuth.get(`${apiEndpoints.jobs}/analysis/add`, {
    params: { jobLeadId },
  })

  return res.data
}

export const fetchJobBatch = async (
  jobIds: string[],
): Promise<JobInterface[]> => {
  const cleaned = jobIds?.filter((id) => !!id.trim())
  if (cleaned && cleaned.length > 0) {
    const res = await apiClientWithAuth.get(`${apiEndpoints.jobs}/batch`, {
      params: { jobIds: jobIds.join(",") },
    })
    return res.data
  } else return []
}

export const fetchJob = async (jobId: string): Promise<JobInterface> => {
  const res = await apiClientWithAuth.get(`${apiEndpoints.job}`, {
    params: { jobId: jobId },
  })
  return res.data
}
