// NOTE: This is duplicated in the models lib, but trying to import from it caused errors - make sure to update the lib enum in ApplicationInterface if changing this
export enum ApplicationStatus {
  APPLIED = "Applied",
  IN_CONTACT = "In Contact",
  PHONE_SCREEN = "Phone Screen",
  INTERVIEW = "Interview",
  OFFER = "Offer",
  REJECTED = "Rejected",
  RESEARCHED = "Researched",
  RAW_LEAD = "Raw Lead",
  COMPLETED = "Completed",
  FOLLOW_UP = "Follow Up",
}

// Duplicated from backend application.interface.ts type.  Could not resolve errors when trying to import it from @relay/models.
// Be sure to update both places if this changes.
export enum ApplicationType {
  QUALITY_APPLY = "Quality Apply",
  EASY_APPLY = "Easy Apply",
  TARGETED = "Targeted",
}

export enum JobProviders {
  LINKEDIN = "linkedin",
  INDEED = "indeed",
  DICE = "dice",
  GLASSDOOR = "glassdoor",
  SIMPLYHIRED = "simplyhired",
  MONSTER = "monster",
  OTHER = "other",
}
