import React from "react"
import "./loading.css"

interface Props {
  size?: number
  alignCenter?: boolean
  spinnerClasses?: string
}
const Loading: React.FC<Props> = ({
  size = 12,
  alignCenter = false,
  spinnerClasses = "mb-4",
}) => (
  <div
    className={`col-span-1 flex justify-center md:col-span-2 lg:col-span-1 ${
      alignCenter ? "items-center" : ""
    }`}
  >
    <div
      className={`loader rounded-full border-4 border-t-4 border-gray-200 ease-linear h-${size} w-${size} ${spinnerClasses}`}
    ></div>
  </div>
)

export default Loading
