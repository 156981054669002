import { OnboardingSteps } from "@relay/interfaces"
import { routes } from "../views/router/routes"

export const isInitialOnboardingComplete = (onboarding: OnboardingSteps) => {
  return onboarding.initial === true
}

export const isOnboardingComplete = (onboarding: OnboardingSteps) => {
  const onboardingKeys = Object.keys(onboarding)
  // @ts-expect-error: FIX: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ preferences?: boolean | undefined; resume?: boolean | undefined; caughtUp?: boolean | undefined; typeform?: boolean | null | undefined; initial?: boolean | undefined; settings?: boolean | undefined; }'. No index signature with a parameter of type 'string' was found on type '{ preferences?: boolean | undefined; resume?: boolean | undefined; caughtUp?: boolean | undefined; typeform?: boolean | null | undefined; initial?: boolean | undefined; settings?: boolean | undefined; }'.
  return onboardingKeys.every((oKey) => onboarding[oKey] === true)
}

export const isInitialOnboardingRoute = (currentRoute: string) =>
  currentRoute === routes.profileEdit

export const getInitialOnboardingRoute = (): string => {
  return routes.profileEdit
}

export const onboardingAlertMessage =
  "Required: Fill out Your Account information."

type StateUpdater = (
  step: Record<string, boolean>,
  onboarding: OnboardingSteps,
) => void

export const processOnboardingStep = async (
  step: keyof OnboardingSteps,
  onboardingStateUpdater: StateUpdater,
  onboarding: OnboardingSteps,
  history: any,
) => {
  await onboardingStateUpdater({ [step]: true }, onboarding)

  const updatedOboarding = { ...onboarding, [step]: true }

  if (isInitialOnboardingComplete(updatedOboarding)) {
    window.scrollTo({ top: 0 })
    // return history.push("/"); //TODO: we should be able to stay within react router, probably need management of state/fetching/update of data
    window.location.href = "/"
  }
}
