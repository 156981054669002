import { apiClientWithAuth, apiEndpoints } from ".."

// Response is "click event sent" message
export const postJobApplyClickEventToAlgolia = async (
  jobId: string,
): Promise<string> => {
  const res = await apiClientWithAuth.post(apiEndpoints.algolia, {
    jobId,
    type: "application",
  })

  return res.data
}

export const updateKnowledgeContent = async (
  knowledgeContent: any,
): Promise<any> => {
  const res = await apiClientWithAuth.put(apiEndpoints.knowledgeBase, {
    knowledgeContent,
  })

  return res.data
}

export const createKnowledgeContent = async (
  knowledgeContent: any,
): Promise<any> => {
  const res = await apiClientWithAuth.post(apiEndpoints.knowledgeBase, {
    knowledgeContent,
  })

  return res.data
}
