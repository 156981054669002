import { OnboardingSteps } from "@relay/interfaces"
import { signOutFirebaseUser } from "../../../firebase-client"
import {
  updateOnboardingStep,
  updateUserName,
} from "../../api-client/apiModules/user"
import { UserState } from "../reducers/userReducer"
import { signOutOfLinkedin } from "../../components/linkedinLogin/signoutOfLinkedin"
import { tr } from "stopword"

export const CLEAR_USER_INFO = "CLEAR_USER_INFO" as const
export const STORE_USER_INFO = "STORE_USER_INFO" as const
export const STORE_USER_FLAGS = "STORE_USER_FLAGS" as const

export interface StoreUserInfoAction {
  type: typeof STORE_USER_INFO
  user: UserState
}

export interface ClearUserAction {
  type: typeof CLEAR_USER_INFO
}

export interface StoreFlagsAction {
  type: typeof STORE_USER_FLAGS
  flags: any
}
export type UserActions =
  | StoreUserInfoAction
  | ClearUserAction
  | StoreFlagsAction

export const storeUserInfo = (user: Partial<UserState>) => ({
  type: STORE_USER_INFO,
  user,
})
export const storeFlags = (flags: any) => ({
  type: STORE_USER_FLAGS,
  flags,
})

export const clearUserInfo = () => ({
  type: CLEAR_USER_INFO,
})

export const startSignoutUser: any = (user: UserState) => {
  // @ts-expect-error: FIX: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    // special case since linkedin doesn't offer elegant way to signout - alternative is we can sign user out of linkedin immediately after getting info we need.
    if (user?.loginProvider && user.loginProvider === "linkedin") {
      signOutOfLinkedin()
    }
    await signOutFirebaseUser()
    await dispatch(clearUserInfo())
  }
}

export const startUpdateOnboarding = (
  onboardingUpdate: Record<string, boolean>,
  userOnboardingState: OnboardingSteps,
) => {
  // @ts-expect-error: FIX: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    try {
      await updateOnboardingStep(onboardingUpdate, userOnboardingState)

      dispatch(
        storeUserInfo({
          onboarding: { ...userOnboardingState, ...onboardingUpdate },
        }),
      )
    } catch (e) {
      console.error("Error updating onboarding for user: ", e)
      throw new Error("Error updating onboarding.")
    }
  }
}

export const startSaveUserNameAndHours = ({
  firstName,
  lastName,
  hoursPerWeek,
}: {
  firstName: string
  lastName: string
  hoursPerWeek: string
}) => {
  // @ts-expect-error: FIX: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    try {
      await updateUserName({ firstName, lastName, hoursPerWeek })
      dispatch(
        storeUserInfo({
          displayName: `${firstName} ${lastName}`,
          hoursPerWeek,
        }),
      )
    } catch (e) {
      console.error("Error updating user name: ", e)
      throw new Error("Error updating user name.")
    }
  }
}

// @ts-expect-error: FIX: Multiple errors, uncomment to see.
export const updateCohortMeetingsUrl = (meetingsUrl, cohortInfo) => {
  // @ts-expect-error: FIX: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    try {
      const newInfo = {
        ...cohortInfo,
        meetingsUrl,
      }
      dispatch(
        storeUserInfo({
          cohortInfo: newInfo,
        }),
      )
    } catch (e) {
      console.error("Error updating cohort meetings url: ", e)
      throw new Error("Error updating cohort meetings url.")
    }
  }
}

// @ts-expect-error: FIX: Parameter 'onboardingTasks' implicitly has an 'any' type.
export const updateOnboardingTasks = (onboardingTasks) => {
  // @ts-expect-error: FIX: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    try {
      dispatch(
        storeUserInfo({
          onboardingTasks: onboardingTasks,
        }),
      )
    } catch (e) {
      console.error("Error updating onboarding tasks: ", e)
      throw new Error("Error updating onboarding tasks.")
    }
  }
}

// @ts-expect-error: FIX: Parameter 'courseProgress' implicitly has an 'any' type.
export const updateCourseProgress = (courseProgress) => {
  // @ts-expect-error: FIX: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    try {
      dispatch(
        storeUserInfo({
          courseInfo: {
            courseProgress,
          },
        }),
      )
    } catch (e) {
      console.error("Error updating courseProgress: ", e)
      throw new Error("Error updating courseProgress.")
    }
  }
}
