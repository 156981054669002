import { combineReducers } from "redux"
import preferencesReducer, { PreferencesState } from "./preferencesReducer"
import { ApplicationProfileState } from "./applicationProfile"
import userReducer, { UserState } from "./userReducer"
import applicationProfileReducer from "./applicationProfile"
import resumeReducer, { ResumeState } from "./resume"
import analysisReducer, { AnalysisState } from "./analysisReducer/analysis"
import publicProfileReducer, {
  PublicProfileState,
} from "./publicProfileReducer"
import triggersReducer, { TriggersState } from "./triggersReducer"
import systemDefaultsReducer, { SystemDefaultsState } from "./systemDefaults"
import applicationsByJobIdReducer, {
  ApplicationsByJobIdState,
} from "./applicationsByJobId"
import interviewsReducer, { InterviewsStoreState } from "./interviewsReducer"

export interface StoreState {
  user: UserState
  preferences: PreferencesState
  applicationProfile: ApplicationProfileState
  resume: ResumeState
  publicProfile: PublicProfileState
  triggers: TriggersState
  analysis: AnalysisState
  systemDefaults: SystemDefaultsState
  applicationsByJobId: ApplicationsByJobIdState
  interviews: InterviewsStoreState
}

export default combineReducers<StoreState>({
  user: userReducer,
  applicationsByJobId: applicationsByJobIdReducer,
  preferences: preferencesReducer,
  applicationProfile: applicationProfileReducer,
  resume: resumeReducer,
  publicProfile: publicProfileReducer,
  triggers: triggersReducer,
  analysis: analysisReducer,
  systemDefaults: systemDefaultsReducer,
  interviews: interviewsReducer,
})
