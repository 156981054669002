import React, { useState, useRef, useEffect } from "react"
import { useSelector } from "react-redux"
import { StoreState } from "../../../redux/reducers"
import { AdminNavigation } from "./adminNavigation"
import { StudentNavigation } from "./studentNavigation"

export function Navigation() {
  const user = useSelector((state: StoreState) => state.user)
  if (user.roles.includes("Admin")) {
    return <AdminNavigation />
  } else {
    return <StudentNavigation />
  }
}
