import { InterviewInterface } from "@relay/interfaces"

export const STORE_INTERVIEWS = "STORE_INTERVIEWS" as const
export const UPSERT_INTERVIEW = "UPSERT_INTERVIEW" as const
export const CLEAR_INTERVIEWS = "CLEAR_INTERVIEWS" as const

export interface StoreInterviewsAction {
  type: typeof STORE_INTERVIEWS
  interviews: InterviewInterface[]
}
export interface UpsertInterviewAction {
  type: typeof UPSERT_INTERVIEW
  interview: InterviewInterface
}
export interface ClearInterviewsAction {
  type: typeof CLEAR_INTERVIEWS
}

export type InterviewsActions =
  | StoreInterviewsAction
  | ClearInterviewsAction
  | UpsertInterviewAction

export const storeInterviews = (interviews: InterviewInterface[]) => ({
  type: STORE_INTERVIEWS,
  interviews,
})

export const upsertInterview = (interview: InterviewInterface) => ({
  type: UPSERT_INTERVIEW,
  interview,
})

export const clearInterviews = () => ({
  type: CLEAR_INTERVIEWS,
})
