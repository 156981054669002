import { fetchRelatedUserData, fetchUser } from "../api-client/apiModules/user"
import { storeApplicationProfile } from "../redux/actions/applicationProfile"
import { storePreferences } from "../redux/actions/preferences"
import { storePublicProfile } from "../redux/actions/publicProfile"
import { storeResume } from "../redux/actions/resume"
import { storeAnalysis } from "../redux/actions/analysis"
import { storeSystemDefaults } from "../redux/actions/system"
import { clearTriggers, storeTriggers } from "../redux/actions/triggers"
import {
  clearUserInfo,
  storeFlags,
  storeUserInfo,
} from "../redux/actions/userActions"
import { initialAppProfileState } from "../redux/reducers/applicationProfile"
import { initialPreferencesState } from "../redux/reducers/preferencesReducer"
import { initialPublicProfileState } from "../redux/reducers/publicProfileReducer"
import { initialSystemDefaultsState } from "../redux/reducers/systemDefaults"
import { initialResumeState } from "../redux/reducers/resume"
import { initialAnalysisState } from "../redux/reducers/analysisReducer/analysis"
import {
  initialUserState,
  UserRolesEnum,
  UserState,
} from "../redux/reducers/userReducer"
import store from "../redux/store"
import { useDispatch } from "react-redux"
import {
  clearAppsByJobId,
  storeAppsByJobId,
} from "../redux/actions/applicationsByJobId"
import { clearInterviews, storeInterviews } from "../redux/actions/interviews"
import { initializeIntegrations } from "./integrations"
// import { SplitSdk, SplitFactoryProvider, SplitTreatments } from '@splitsoftware/splitio-react';

// @ts-expect-error: FIX: Parameter 'googleUser' implicitly has an 'any' type.
export const loadUser = async (googleUser): Promise<UserState> => {
  const fetchedUser = await fetchUser()
  const roles = fetchedUser.roles || initialUserState.roles
  const group = fetchedUser.group || initialUserState.group
  const groupInfo = fetchedUser.groupInfo || initialUserState.groupInfo
  const cohort = fetchedUser.cohort || initialUserState.cohort
  const cohortInfo = fetchedUser.cohortInfo || initialUserState.cohortInfo
  const course = fetchedUser.course || initialUserState.course
  const courseInfo = fetchedUser.courseInfo || initialUserState.courseInfo
  const programInfo = fetchedUser.programInfo || initialUserState.programInfo
  const latest = fetchedUser.latest || initialUserState.latest
  const mentor = fetchedUser.mentor || initialUserState.mentor
  const admin =
    (fetchedUser.roles as string[])?.includes(UserRolesEnum.ADMIN) || false
  const { displayName, photoURL, email, uid } = googleUser
  const phoneNumber =
    fetchedUser.phoneNumber ||
    googleUser.phoneNumber ||
    initialUserState.phoneNumber
  const slackId = fetchedUser.slackId || initialUserState.slackId
  const courseHistory = fetchedUser.courseHistory || []
  const hoursPerWeek = fetchedUser.hoursPerWeek || ""
  const dateOfBirth = fetchedUser.dateOfBirth || initialUserState.dateOfBirth
  const address = fetchedUser.address || initialUserState.address
  const linkedinProfile =
    fetchedUser.linkedinProfile || initialUserState.linkedinProfile
  const linkedinURL = fetchedUser.linkedinURL || initialUserState.linkedinURL
  const onboardingTasks =
    fetchedUser.onboardingTasks || initialUserState.onboardingTasks

  const parsedUser: UserState = {
    displayName: fetchedUser
      ? `${fetchedUser.firstName} ${fetchedUser.lastName}`
      : displayName,
    photoURL,
    email,
    phoneNumber,
    uid,
    admin,
    roles,
    active: fetchedUser.active,
    group,
    groupInfo,
    cohort,
    cohortInfo,
    onboarding: fetchedUser.onboarding || initialUserState.onboarding,
    course,
    courseHistory,
    courseInfo,
    programInfo,
    latest,
    mentor,
    slackId,
    hoursPerWeek,
    loginProvider: fetchedUser.loginProvider || "google",
    address,
    dateOfBirth,
    linkedinProfile,
    linkedinURL,
    startDate: fetchedUser.startDate || initialUserState.startDate,
    onboardingTasks,
    endDate: fetchedUser.endDate || initialUserState.endDate,
    program: fetchedUser.program || initialUserState.program,
  }

  store.dispatch(storeUserInfo(parsedUser))
  return parsedUser
}
// @ts-expect-error: FIX: Parameter 'factory' implicitly has an 'any' type.
export const loadInitialFlags = async (factory) => {
  const flags = ["course_access", "course_admin"]
  const flagsObject = flags.reduce((acc, flag) => {
    // @ts-expect-error: FIX: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'. No index signature with a parameter of type 'string' was found on type '{}'.
    acc[flag] = "no"
    return acc
  }, {})

  const client = factory.client()
  client.on(client.Event.SDK_READY, function () {
    const treatments = client.getTreatments([
      "course_access",
      "course_admin",
      "tests",
    ])
    store.dispatch(storeFlags(treatments))
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    client.destroy().then(function () {})
  })
}
export const loadRelatedUserData = async (user: UserState) => {
  const {
    triggers,
    preferences,
    allAnalyses,
    resume,
    appProfile,
    systemDefaults,
    applicationsGroupedByJobId,
    interviews = [],
  } = await fetchRelatedUserData(user.uid)

  store.dispatch(storeTriggers(triggers))
  store.dispatch(storePreferences(preferences))
  store.dispatch(storeAnalysis(allAnalyses))
  store.dispatch(storeResume(resume))
  store.dispatch(storeApplicationProfile(appProfile))

  if (user?.groupInfo?.site?.active) {
    store.dispatch(
      storeSystemDefaults({
        ...systemDefaults,
        site: user?.groupInfo?.site,
      }),
    )
    initializeIntegrations(user.groupInfo.site.integrations, user)
  } else {
    store.dispatch(
      storeSystemDefaults({
        ...systemDefaults,
      }),
    )
  }

  store.dispatch(storeAppsByJobId(applicationsGroupedByJobId))
  store.dispatch(storeInterviews(interviews))
}

export const clearUserData = () => {
  store.dispatch(clearUserInfo())
  store.dispatch(clearTriggers())
  store.dispatch(storePreferences(initialPreferencesState))
  store.dispatch(storeApplicationProfile(initialAppProfileState))
  store.dispatch(storePublicProfile(initialPublicProfileState))
  store.dispatch(storeResume(initialResumeState))
  store.dispatch(storeAnalysis(initialAnalysisState))
  store.dispatch(storeSystemDefaults(initialSystemDefaultsState))
  store.dispatch(clearAppsByJobId())
  store.dispatch(clearInterviews())
}
