/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useRef, useEffect } from "react"
import { Transition } from "@headlessui/react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useHistory } from "react-router-dom"
import { startSignoutUser } from "../../../redux/actions/userActions"
import { StoreState } from "../../../redux/reducers"
import logo from "../../../../assets/img/JLT-logo.svg"
import ViewNotifications from "./notifications/viewNotifications"
import { routes } from "../../../views/router/routes"
import toast from "react-hot-toast"
import Dropdown from "../../header/navigation/dropdown/dropdown"
import "./navigation.css"

export function StudentNavigation() {
  const [showUserMenu, setShowUserMenu] = useState(false)
  const [showDropDown, setShowDropDown] = useState(false)
  const container = useRef(null)
  const [showNotifications, setShowNotifications] = useState(false)
  const [showSuperAdminMenu, setShowSuperAdminMenu] = useState(false)
  const [showCoachMenu, setShowCoachMenu] = useState(false)
  const [mobileMenu, setMobileMenu] = useState(false)
  const [showJobhunt, setShowJobhunt] = useState(false)
  const [headers, setHeaders] = useState([])
  const [currentHeader, setCurrentHeader] = useState("/")
  const site = useSelector((state: StoreState) => state.systemDefaults.site)
  const user = useSelector((state: StoreState) => state.user)
  const dispatch = useDispatch()

  const isUserSignedIn = !!user.uid && user.active

  useEffect(() => {
    // @ts-expect-error: FIX: 'site.header' is possibly 'undefined'.
    const headers = site.header.links.sort(function (a, b) {
      return (a?.order || 10) - (b?.order || 10)
    })

    for (let i = 0; i < headers.length; i++) {
      // @ts-expect-error: FIX: Object is possibly 'undefined'.
      if (headers[i].flags) {
        // @ts-expect-error: FIX: Object is possibly 'undefined'.
        for (const flag in headers[i].flags) {
          if (flag.includes("admin")) {
            //THIS ADMIN CHECK IS BECAUSE IF IT'S ON FOR ADMINS IT SHOULD NOT SHOW UP FOR STUDENTS, ADDITIONALLY THE STUDENT AND ADMIN NAVIGATIONS HAVE BEEN SEPERATED (SEE 4 FILES ABOVE THIS ONE 'adminNavigation.tsx')
            continue
          }
          // @ts-expect-error: FIX: Object is possibly 'undefined'.
          if (headers[i].flags?.[flag] !== user.flags?.[flag]) {
            headers.splice(i, 1)
            break
          }
        }
      }
    }
    // @ts-expect-error: FIX: Argument of type 'Links[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
    setHeaders(headers)
    const currentUrl = window.location.pathname
    setCurrentHeader(currentUrl)
  }, [user, site])

  const history = useHistory()
  useEffect(() => {
    history.listen((location) => {
      const currentUrl = window.location.pathname
      setCurrentHeader(currentUrl)
    })
  }, [history])

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // @ts-expect-error: FIX: Property 'contains' does not exist on type 'never'.
      if (!container?.current?.contains(event.target)) {
        if (!showUserMenu) return
        setShowUserMenu(false)
        setShowDropDown(false)
      }
    }

    window.addEventListener("click", handleOutsideClick)
    return () => window.removeEventListener("click", handleOutsideClick)
  }, [showUserMenu, container])

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!showUserMenu) return

      if (event.key === "Escape") {
        setShowUserMenu(false)
        setShowDropDown(false)
      }
    }

    document.addEventListener("keyup", handleEscape)
    return () => document.removeEventListener("keyup", handleEscape)
  }, [showUserMenu])

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!showNotifications) return

      if (event.key === "Escape") {
        setShowNotifications(false)
      }
    }

    document.addEventListener("keyup", handleEscape)
    return () => document.removeEventListener("keyup", handleEscape)
  }, [showNotifications])

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!showSuperAdminMenu) return

      if (event.key === "Escape") {
        setShowSuperAdminMenu(false)
      }
    }

    document.addEventListener("keyup", handleEscape)
    return () => document.removeEventListener("keyup", handleEscape)
  }, [showSuperAdminMenu])

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!showCoachMenu) return

      if (event.key === "Escape") {
        setShowCoachMenu(false)
      }
    }

    document.addEventListener("keyup", handleEscape)
    return () => document.removeEventListener("keyup", handleEscape)
  }, [showCoachMenu])

  const onSignoutClick = async () => {
    try {
      await dispatch(startSignoutUser(user))
      setShowUserMenu(!showUserMenu)
      setShowDropDown(!showDropDown)
    } catch (e) {
      console.error("Error signing out. ", e)
      toast.error("Error signing out.")
    }
  }

  // @ts-expect-error: FIX: Multiple errors, uncomment to see.
  const navClick = (id, mobile) => {
    if (mobile) {
      // @ts-expect-error: FIX: Object is possibly 'undefined'.
      setCurrentHeader(mobileMenuHeaders[id].interUrl)
    } else {
      // @ts-expect-error: FIX: Object is possibly 'undefined'.
      setCurrentHeader(headers[id].interUrl)
    }
    if (mobileMenu) {
      setMobileMenu(!mobileMenu)
    }
  }

  // @ts-expect-error: FIX: Parameter 'path' implicitly has an 'any' type.
  const onMenuItemClick = (path) => {
    setShowCoachMenu(false)
    setShowUserMenu(false)
    setShowDropDown(false)
    setShowSuperAdminMenu(false)
    setCurrentHeader(path)
  }

  const defaultUserPhotoURL =
    "https://www.gravatar.com/avatar/00000000000000000000000000000000"
  const userPhotoURL = user.photoURL || defaultUserPhotoURL

  const mobileMenuHeaders = [
    { name: "Your Account", interUrl: routes.profile },
    {
      name: "Sign Out",
      interUrl: routes.login,
      onClick: () => onSignoutClick(),
    },
  ]

  // @ts-expect-error: FIX: Rest parameter 'classes' implicitly has an 'any[]' type.
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }
  let courseTracking = {} as any
  if (user && user.course && user.courseInfo) {
    courseTracking = user.courseInfo.courseTracking
  }

  // @ts-expect-error: FIX: 'site.images' is possibly 'undefined'.
  let siteLogo: any = site.images.logo || logo

  if (window.location.pathname === routes.login) {
    siteLogo = false
  }
  return (
    <>
      {user.email && (
        <nav
          // @ts-expect-error: FIX: Multiple errors, uncomment to see.
          className={user.email ? site.colors.background.navbar : "bg-gray-900"}
        >
          <div className="max-w-8xl mx-auto border-b px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex px-2 lg:px-0">
                <div className="flex flex-shrink-0 items-center">
                  {siteLogo ? (
                    <img
                      className="hidden h-9 w-auto lg:block"
                      src={siteLogo}
                      alt="Logo"
                    ></img>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                  <div
                    className="relative z-50 flex-shrink-0"
                    ref={container}
                    onMouseLeave={() => setShowJobhunt(false)}
                  >
                    <div>
                      {isUserSignedIn && (
                        <div className="hidden sm:ml-6 sm:block">
                          <div className="flex items-center space-x-4">
                            {user.onboarding.initial
                              ? headers.map((item, idx) =>
                                  // @ts-expect-error: FIX: Property 'name' does not exist on type 'never'.
                                  (item.name === "Jobs" ||
                                    // @ts-expect-error: FIX: Property 'name' does not exist on type 'never'.
                                    item.name === "Applications" ||
                                    // @ts-expect-error: FIX: Property 'name' does not exist on type 'never'.
                                    item.name === "Analysis") &&
                                  !user.roles.includes("Job Hunter") ? (
                                    <></>
                                  ) : // @ts-expect-error: FIX: Property 'role' does not exist on type 'never'.
                                  !item.role ||
                                    // @ts-expect-error: FIX: Property 'role' does not exist on type 'never'.
                                    user.roles.includes(item.role) ? (
                                    // @ts-expect-error: FIX: Property 'type' does not exist on type 'never'.
                                    item.type === "dropdown" ? (
                                      <Dropdown
                                        onClick={() => navClick(idx, false)}
                                        // @ts-expect-error: FIX: Property 'interUrl' does not exist on type 'never'.
                                        active={item.interUrl === currentHeader}
                                        // @ts-expect-error: FIX: Property 'name' does not exist on type 'never'.
                                        label={item.name}
                                        // @ts-expect-error: FIX: Property 'links' does not exist on type 'never'.
                                        options={item.links}
                                      />
                                    ) : // @ts-expect-error: FIX: Property 'interUrl' does not exist on type 'never'.
                                    item.interUrl ? (
                                      <NavLink
                                        key={`nav-link-non-mobile-${idx}`}
                                        exact
                                        // @ts-expect-error: FIX: Property 'interUrl' does not exist on type 'never'.
                                        to={item.interUrl}
                                        onClick={() => navClick(idx, false)}
                                        className={classNames(
                                          // @ts-expect-error: FIX: Property 'interUrl' does not exist on type 'never'.
                                          item.interUrl === currentHeader
                                            ? // @ts-expect-error: FIX: Multiple errors, uncomment to see.
                                              `${site.colors.active.navbar_background} ${site.colors.active.navbar_text}` //
                                            : `hover:bg-[#EEEDF1]`, //text-gray-300 hover:bg-gray-700 hover:text-white
                                          "rounded-md px-3 py-2 font-medium",
                                        )}
                                        aria-current={
                                          // @ts-expect-error: FIX: Property 'interUrl' does not exist on type 'never'.
                                          item.interUrl === currentHeader
                                            ? "page"
                                            : undefined
                                        }
                                        role="menuitem"
                                      >
                                        {/* @ts-expect-error: FIX: Property 'name' does not exist on type 'never'. */}
                                        {item.name}
                                      </NavLink>
                                    ) : (
                                      <a
                                        // @ts-expect-error: FIX: Property 'url' does not exist on type 'never'.
                                        href={item.url}
                                        target="_blank"
                                        className={`rounded-md px-3 py-2 font-medium text-black hover:bg-[#EEEDF1]`}
                                        aria-current={
                                          // @ts-expect-error: FIX: Property 'interUrl' does not exist on type 'never'.
                                          item.interUrl === currentHeader
                                            ? "page"
                                            : undefined
                                        }
                                        rel="noreferrer"
                                      >
                                        {/* @ts-expect-error: FIX: Property 'name' does not exist on type 'never'. */}
                                        {item.name}
                                      </a>
                                    )
                                  ) : null,
                                )
                              : null}
                          </div>
                        </div>
                      )}
                      {!isUserSignedIn && (
                        <div className="hidden sm:ml-2 sm:block">
                          <div className="flex space-x-4">
                            {/* <NavLink
                          key={`nav-link-non-mobile-pricing`}
                          exact
                          to="/beta"
                          className={classNames(
                            '/beta' === currentHeader
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'px-3 py-2 rounded-md text-sm font-medium'
                          )}
                          aria-current={
                            '/beta' === currentHeader ? 'page' : undefined
                          }
                          role="menuitem"
                        >
                          Beta
                        </NavLink> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center lg:hidden">
                <button
                  type="button"
                  // @ts-expect-error: FIX: Multiple errors, uncomment to see.
                  className={`inline-flex items-center justify-center rounded-md p-2 ${site.colors.text.navbar} ${site.colors.hover.navbar_background} ${site.colors.hover.navbar_border} ${site.colors.hover.navbar_text} focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500`}
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                  onClick={() => setMobileMenu(!mobileMenu)}
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="white"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  <svg
                    className="hidden h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              {isUserSignedIn && (
                <div className="hidden lg:flex lg:items-center">
                  {user.onboarding.initial ? (
                    <ViewNotifications
                      showNotifications={showNotifications}
                      setShowNotificationsList={setShowNotifications}
                    />
                  ) : null}
                  <div className="relative z-50 flex-shrink-0" ref={container}>
                    <div>
                      <button
                        onClick={() => setShowUserMenu(!showUserMenu)}
                        className="icon-secondary border-none shadow-none"
                        style={{ backgroundColor: "#FFF", border: "none" }}
                        id="user-menu"
                        aria-haspopup="true"
                      >
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-7 w-7 rounded-full"
                          src={userPhotoURL}
                          referrerPolicy="no-referrer"
                          alt=""
                        ></img>
                      </button>
                    </div>
                    <Transition
                      show={showUserMenu}
                      enter="transition ease-out duration-100 transform"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="transition ease-in duration-75 transform"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <div
                        className="absolute right-0 mt-2 w-60 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu"
                      >
                        {/* <NavLink
                      onClick={() => userMenuClicks(false, '/profile')}
                      exact
                      to="/profile"
                      activeClassName="bg-gray-100"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      Your Profile
                    </NavLink> */}

                        {user.onboarding.initial ? (
                          <NavLink
                            onClick={() => onMenuItemClick(routes.profile)}
                            exact
                            to={routes.profile}
                            activeClassName="bg-gray-100"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            Your Account
                          </NavLink>
                        ) : null}
                        {/* <NavLink
                      onClick={() => onMenuItemClick('/preferences')}
                      exact
                      to="/preferences"
                      activeClassName="bg-gray-100"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Preferences
                    </NavLink>
                    <NavLink
                      onClick={() => onMenuItemClick('/settings')}
                      exact
                      to="/settings"
                      activeClassName="bg-gray-100"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Settings
                    </NavLink> */}

                        {/* <NavLink
                      onClick={() =>
                        userMenuClicks(false, '/application-analysis')
                      }
                      exact
                      to="/application-analysis"
                      activeClassName="bg-gray-100"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Application Analysis
                    </NavLink> */}
                        {/* <NavLink
                          onClick={onSignoutClick}
                          exact
                          to="/login"
                          activeClassName="bg-gray-100"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                        >
                          Sign out
                        </NavLink> */}
                        <button
                          onClick={onSignoutClick}
                          className="m-0 block w-full px-4 py-2 text-left text-sm text-gray-700 shadow-none"
                          role="menuitem"
                          id="sign-out-btn"
                        >
                          Sign out
                        </button>
                      </div>
                    </Transition>
                  </div>
                </div>
              )}
            </div>
          </div>

          {isUserSignedIn && mobileMenu && (
            <div className="lg:hidden" id="mobile-menu">
              <div className="space-y-1 pb-3 pt-2">
                {user.onboarding.initial
                  ? headers.map((item, idx) =>
                      // @ts-expect-error: FIX: Property 'name' does not exist on type 'never'.
                      item.name === "Course" ? (
                        <></>
                      ) : // @ts-expect-error: FIX: Property 'interUrl' does not exist on type 'never'.
                      item.interUrl ? (
                        <NavLink
                          key={idx}
                          exact
                          // @ts-expect-error: FIX: Property 'interUrl' does not exist on type 'never'.
                          to={item.interUrl}
                          onClick={() => navClick(idx, false)}
                          className={classNames(
                            // @ts-expect-error: FIX: Property 'interUrl' does not exist on type 'never'.
                            item.interUrl === currentHeader
                              ? // @ts-expect-error: FIX: Multiple errors, uncomment to see.
                                `${site.colors.active.navbar_background} ${site.colors.active.navbar_border} ${site.colors.active.navbar_text}`
                              : // @ts-expect-error: FIX: Multiple errors, uncomment to see.
                                `border-transparent ${site.colors.text.navbar} ${site.colors.hover.navbar_background} ${site.colors.hover.navbar_border} ${site.colors.hover.navbar_text}`,
                            "block border-l-4 py-2 pl-3 pr-4 text-base font-medium",
                          )}
                          aria-current={
                            // @ts-expect-error: FIX: Property 'interUrl' does not exist on type 'never'.
                            item.interUrl === currentHeader ? "page" : undefined
                          }
                          role="menuitem"
                        >
                          {/* @ts-expect-error: FIX: Property 'name' does not exist on type 'never'. */}
                          {item.name}
                        </NavLink>
                      ) : (
                        <a
                          // @ts-expect-error: FIX: Property 'url' does not exist on type 'never'.
                          href={item.url}
                          target="_blank"
                          // @ts-expect-error: FIX: Multiple errors, uncomment to see.
                          className={`border-transparent ${site.colors.text.navbar} ${site.colors.hover.navbar_background} ${site.colors.hover.navbar_border} ${site.colors.hover.navbar_text} block border-l-4 py-2 pl-3 pr-4 text-base font-medium`}
                          aria-current={
                            // @ts-expect-error: FIX: Property 'interUrl' does not exist on type 'never'.
                            item.interUrl === currentHeader ? "page" : undefined
                          }
                          rel="noreferrer"
                        >
                          {/* @ts-expect-error: FIX: Property 'name' does not exist on type 'never'. */}
                          {item.name}
                        </a>
                      ),
                    )
                  : null}
              </div>

              <div className="border-t border-gray-200 pb-3 pt-4">
                {user.onboarding.initial ? (
                  <ViewNotifications
                    showNotifications={showNotifications}
                    setShowNotificationsList={setShowNotifications}
                    isMobile={true}
                  />
                ) : null}
                <div className="mt-2 space-y-1">
                  {mobileMenuHeaders.map((item, idx) => (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                      {user.onboarding.initial ? (
                        <NavLink
                          key={`nav-link-mobile-${idx}`}
                          exact
                          to={item.interUrl}
                          onClick={
                            item.onClick
                              ? item.onClick
                              : () => navClick(idx, true)
                          }
                          className={classNames(
                            item.interUrl === currentHeader
                              ? // @ts-expect-error: FIX: Multiple errors, uncomment to see.
                                `${site.colors.active.navbar_background} ${site.colors.active.navbar_border} ${site.colors.active.navbar_text}`
                              : // @ts-expect-error: FIX: Multiple errors, uncomment to see.
                                `border-transparent ${site.colors.text.navbar} ${site.colors.hover.navbar_background} ${site.colors.hover.navbar_border} ${site.colors.hover.navbar_text}`,
                            "block border-l-4 py-2 pl-3 pr-4 text-base font-medium",
                          )}
                          aria-current={
                            item.interUrl === currentHeader ? "page" : undefined
                          }
                          role="menuitem"
                        >
                          {item.name}
                        </NavLink>
                      ) : item.name === "Sign Out" ? (
                        <NavLink
                          key={`nav-link-mobile-${idx}`}
                          exact
                          to={item.interUrl}
                          onClick={
                            item.onClick
                              ? item.onClick
                              : () => navClick(idx, true)
                          }
                          className={classNames(
                            item.interUrl === currentHeader
                              ? // @ts-expect-error: FIX: Multiple errors, uncomment to see.
                                `${site.colors.active.navbar_background} ${site.colors.active.navbar_border} ${site.colors.active.navbar_text}`
                              : // @ts-expect-error: FIX: Multiple errors, uncomment to see.
                                `border-transparent ${site.colors.text.navbar} ${site.colors.hover.navbar_background} ${site.colors.hover.navbar_border} ${site.colors.hover.navbar_text}`,
                            "block border-l-4 py-2 pl-3 pr-4 text-base font-medium",
                          )}
                          aria-current={
                            item.interUrl === currentHeader ? "page" : undefined
                          }
                          role="menuitem"
                        >
                          {item.name}
                        </NavLink>
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            </div>
          )}
        </nav>
      )}
    </>
  )
}
