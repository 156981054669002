import React, { useEffect } from "react"
import AdminMenu from "./AdminMenu"

// @ts-expect-error: FIX: Binding element 'mobileMenu' implicitly has an 'any' type.
const MobileAdminMenu = ({ mobileMenu }) => {
  return (
    <div className="flex flex-col">
      <AdminMenu mobileMenu={mobileMenu} />
    </div>
  )
}

export default MobileAdminMenu
