import { InterviewInterface } from "@relay/interfaces"
import {
  CLEAR_INTERVIEWS,
  InterviewsActions,
  STORE_INTERVIEWS,
  UPSERT_INTERVIEW,
} from "../actions/interviews"

export type InterviewsStoreState = InterviewInterface[]

export const initialInterviewsState = []

const replaceInterview = (
  interviews: InterviewInterface[],
  replace: InterviewInterface,
): InterviewInterface[] => {
  return interviews.map((i) => (i.id === replace.id ? replace : i))
}

export default (
  state: InterviewsStoreState = initialInterviewsState,
  action: InterviewsActions,
): InterviewsStoreState => {
  switch (action.type) {
    case UPSERT_INTERVIEW: {
      const found = state.find((i) => action.interview.id === i.id)
      return found
        ? replaceInterview([...state], action.interview)
        : [...state, action.interview]
    }
    case STORE_INTERVIEWS:
      return [...state, ...action.interviews]
    case CLEAR_INTERVIEWS:
      return initialInterviewsState
    default:
      return state
  }
}
