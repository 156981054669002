import { PreferencesInterface } from "@relay/interfaces"
import { postPreferences } from "../../api-client/apiModules/preferences"

export const STORE_PREFERENCES = "STORE_PREFERENCES" as const

export interface StorePreferencesAction {
  type: typeof STORE_PREFERENCES
  payload: PreferencesInterface
}

export type PreferencesActions = StorePreferencesAction

export const storePreferences = (preferences: PreferencesInterface) => ({
  type: STORE_PREFERENCES,
  payload: preferences,
})

export const startSavePreferences = (preferencesBody: PreferencesInterface) => {
  // @ts-expect-error: FIX: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    try {
      const prefs = await postPreferences(preferencesBody)
      dispatch(storePreferences(prefs))
    } catch (e) {
      console.error("Error storing Preferences.", e)
      throw new Error("Error saving preferences")
    }
  }
}
