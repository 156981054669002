import { getAuth, onAuthStateChanged } from "firebase/auth"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import App from "./app/app"
import LoadingOverlay from "./app/components/loadingOverlay/LoadingOverlay"
import store from "./app/redux/store"
import { loadGooglePlacesAPI } from "./app/util/googlePlacesApi"
import createActivityDetector from "./app/util/activity"
import envVars from "./environments/envConfig"
import { BrowserRouter } from "react-router-dom"

import {
  clearUserData,
  loadRelatedUserData,
  loadUser,
  loadInitialFlags,
} from "./app/util/loadUserData"
import { firebaseApp } from "./firebase-client"
import { SplitSdk, SplitFactoryProvider } from "@splitsoftware/splitio-react"
import * as Sentry from "@sentry/react"

const SITE_URL = {
  production: "https://hub.vschool.io",
  test: "https://test.jobleads.today",
}

Sentry.init({
  dsn: "https://cf7a0bb27f3eb353511ac35db4b3eaad@o4507488850870272.ingest.us.sentry.io/4507488943013888",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.NODE_ENV || "local",
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", SITE_URL.test, SITE_URL.production],
  // Session Replay
  replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const LOADING_MESSAGE = "Loading your learning platform..."
const SplitAPIKey = envVars.splitAPIKey

ReactDOM.render(
  <LoadingOverlay loadingMessage={LOADING_MESSAGE} />,
  document.getElementById("root"),
)

interface OverlayArgs {
  error?: boolean
  message?: string
}
const renderLoadingOverlay = (props?: OverlayArgs) => {
  ReactDOM.render(
    <LoadingOverlay
      error={props?.error || false}
      loadingMessage={props?.message || LOADING_MESSAGE}
    />,
    document.getElementById("root"),
  )
}

// @ts-expect-error: FIX: Parameter 'factory' implicitly has an 'any' type.
const renderApp = (factory?) => {
  const appJsx = (
    <React.StrictMode>
      <SplitFactoryProvider factory={factory}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </SplitFactoryProvider>
    </React.StrictMode>
  )
  ReactDOM.render(appJsx, document.getElementById("root"))
}

loadGooglePlacesAPI()

const auth = getAuth(firebaseApp)
try {
  const timeToIdle = 30 * 60 * 1000

  const activityDetector = createActivityDetector({
    autoInit: true,
    timeToIdle: timeToIdle,
  })

  activityDetector.on("idle", () => {
    if (auth?.currentUser) {
      auth.signOut()
    }
  })
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  activityDetector.on("active", () => {})
} catch (error) {
  console.log(error, "Error in activity detector")
}

onAuthStateChanged(auth, async (user) => {
  const factory: any = SplitSdk({
    core: {
      authorizationKey: SplitAPIKey,
      key: user?.uid || "anonymous",
    },
  })
  if (user) {
    try {
      loadInitialFlags(factory)
    } catch (error) {
      console.log("Error loading flags", error)
    }
    try {
      renderLoadingOverlay()
      const parsedUser = await loadUser(user)
      if (parsedUser.active || parsedUser.admin) {
        await loadRelatedUserData(parsedUser) // This is otherwise done on successful access token submit in the enter access token form
      }

      renderApp(factory)
    } catch (e) {
      console.error("User saved data fetch failed. ", e)
      renderLoadingOverlay({
        error: true,
        message:
          "There was an error. Contact the Site Admin if the problem persists.",
      })
    }
  } else {
    clearUserData()
    renderApp(
      SplitSdk({
        core: {
          authorizationKey: SplitAPIKey,
          key: "anonymous",
        },
      }),
    )
  }
})
