import {
  AnalysisInterface,
  HistoryAnalysisInterface,
  InterviewAnalysis,
  TaskAnalysis,
} from "@relay/interfaces"
import { AnalysisType } from "../../../shared/types/analysis"
import { AnalysisActions, STORE_ANALYSIS } from "../../actions/analysis"
import {
  initAnalysis,
  initHistoryAnalysis,
  initInterviewAnalysis,
  initTaskAnalysis,
} from "./analysisReducerUtils"

export type AnalysisState = {
  [AnalysisType.APPLICATIONS]: AnalysisInterface
  [AnalysisType.JOBMARKET]: AnalysisInterface
  [AnalysisType.INTEREST]: AnalysisInterface
  [AnalysisType.RESUME]: AnalysisInterface
  [AnalysisType.HISTORY]: HistoryAnalysisInterface
  [AnalysisType.TASK]: TaskAnalysis
  [AnalysisType.INTERVIEW]: InterviewAnalysis
}

export const initialAnalysisState: AnalysisState = {
  [AnalysisType.APPLICATIONS]: initAnalysis,
  [AnalysisType.JOBMARKET]: initAnalysis,
  [AnalysisType.INTEREST]: initAnalysis,
  [AnalysisType.RESUME]: initAnalysis,
  [AnalysisType.HISTORY]: initHistoryAnalysis,
  [AnalysisType.TASK]: initTaskAnalysis,
  [AnalysisType.INTERVIEW]: initInterviewAnalysis,
}

export default (
  state: AnalysisState = initialAnalysisState,
  action: AnalysisActions,
) => {
  switch (action.type) {
    case STORE_ANALYSIS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
