import { ApplicationProfileInterface } from "@relay/interfaces"
import { postApplicationProfile } from "../../api-client/apiModules/applicationProfile"

export const STORE_APPLICATION_PROFILE = "STORE_APPLICATION_PROFILE" as const

export interface StoreApplicationProfileAction {
  type: typeof STORE_APPLICATION_PROFILE
  payload: ApplicationProfileInterface
}

export type ApplicationProfileActions = StoreApplicationProfileAction

export const storeApplicationProfile = (
  appProfile: Partial<ApplicationProfileInterface>,
) => ({
  type: STORE_APPLICATION_PROFILE,
  payload: appProfile,
})

export const startSaveApplicationProfile = (
  applicationProfileBody: ApplicationProfileInterface,
) => {
  // @ts-expect-error: FIX: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    try {
      const profile = await postApplicationProfile(applicationProfileBody)
      dispatch(storeApplicationProfile(profile))
    } catch (e) {
      console.error("Error storing application profile.", e)
    }
  }
}
