import React from "react"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { NotificationInterface } from "@relay/interfaces"
import { NavLink } from "react-router-dom"
import { updateNotification } from "../../../api-client/apiModules/notifications"
import { CheckCircleIcon } from "@heroicons/react/solid"
dayjs.extend(utc)

const NotificationWrapper: React.FC<{
  notificationUrl: string
  children: any
}> = ({ children, notificationUrl }) => {
  if (notificationUrl)
    return (
      <a href={notificationUrl} target="_blank" rel="noreferrer">
        {children}
      </a>
    )
  return <div>{children}</div>
}
interface Props {
  notification: NotificationInterface
  removeNotification: (id: string) => void
}

const NotificationTile: React.FunctionComponent<Props> = ({
  notification,
  removeNotification,
}) => {
  // @ts-expect-error: FIX: Parameter 'e' implicitly has an 'any' type.
  const setNotificationAsViewed = async (e) => {
    console.log(notification)
    e.preventDefault()
    e.stopPropagation()

    try {
      await updateNotification(notification.id, { viewed: true })
      removeNotification(notification.id)
    } catch (e) {
      alert(
        "Error updating notification. Contact site administrator if error persists.",
      )
    }
  }
  return notification.internalLink ? (
    <NavLink exact to={notification.internalLink}>
      <div className="flex border-b">
        <div className="block rounded-md border border-transparent px-2 text-sm font-medium text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
          <div className="flex flex-row">
            <div className="flex items-center pr-2">
              <CheckCircleIcon className="h-6 w-6" />
            </div>

            <div>
              <h3 className="m-0 py-2 font-medium">{notification.message}</h3>
              <p className="text-gray-600">Subject: {notification.subject}</p>
              <div className="flex justify-between">
                <p className="py-2 text-xs">
                  {dayjs(notification.createdDate).local().format("MM/DD/YYYY")}
                </p>
                <button
                  onClick={setNotificationAsViewed}
                  style={{ zIndex: 999999 }}
                  className="m-0 border-none bg-transparent p-0 py-2 text-left text-sky-800 underline shadow-none hover:underline"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  ) : (
    // @ts-expect-error: FIX: Type 'string | undefined' is not assignable to type 'string'. Type 'undefined' is not assignable to type 'string'.
    <NotificationWrapper notificationUrl={notification.url}>
      <div className="flex border-b">
        <div className="block rounded-md border border-transparent px-2 text-sm font-medium text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
          <div className="flex flex-row">
            <div className="flex items-center pr-2">
              {notification.logoUrl && (
                <img
                  className="h-8 w-8 object-contain"
                  src={notification.logoUrl}
                  alt="notification-thumb"
                ></img>
              )}
            </div>

            <div>
              <h3 className="m-0 py-2 font-medium">{notification.message}</h3>
              <p className="text-gray-600">Subject: {notification.subject}</p>
              <div className="flex justify-between">
                <p className="py-2 text-xs">
                  {dayjs(notification.createdDate).local().format("MM/DD/YYYY")}
                </p>
                <button
                  onClick={setNotificationAsViewed}
                  style={{ zIndex: 999999 }}
                  className="m-0 p-0 py-2 text-left text-sky-800 underline shadow-none hover:underline"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NotificationWrapper>
  )
}

export default NotificationTile
