import { SearchDocumentResponse, SiteInterface } from "@relay/interfaces"

import { SystemDefaultsActions, STORE_SYSTEM_DEFAULTS } from "../actions/system"
export interface SystemSettings {
  version: string
  environment: string
  set: string
}

export interface SearchesSettings {
  [searchId: string]: SearchDocumentResponse
}
export interface SystemDefaultsState {
  settings: SystemSettings
  searches: SearchesSettings
  site: SiteInterface
}

export const initialSystemDefaultsState: SystemDefaultsState = {
  settings: { version: "", environment: "", set: "" },
  searches: {},
  site: {
    active: false,
    images: {
      icon: "/assets/label/vschool/vschool_full.png",
      ico: "/assets/label/vschool/vschool.ico",
      logo: "/assets/label/vschool/vschool.png",
    },
    content: {
      acronym: "VS",
      title: "V SChool",
    },
    colors: {
      background: {
        main: "bg-gray-100",
        sections: "bg-white",
        navbar: "bg-white",
        application_card: "bg-blue-50",
        button: "bg-sky-600",
        footer: "bg-white",
        job: "bg-blue-50",
        task: "bg-blue-50",
      },
      hover: {
        navbar_background: "hover:bg-[#EEEDF1]",
        navbar_text: "hover:text-gray-800",
        navbar_border: "hover:border-gray-300",
        button: "hover:bg-sky-400",
        task: "hover:bg-blue-50",
        job: "hover:bg-blue-50",
      },
      active: {
        navbar_background: "bg-gray-900 ",
        navbar_text: "text-white",
        navbar_border: "border-gray-300",
      },
      text: {
        navbar: "text-gray-300",
        footer: "text-gray-400",
        button: "text-white",
      },
    },
    footer: {
      text: "2022 V School, LLC All rights reserved.",
    },
    header: {
      links: [
        { name: "Home", interUrl: "/" },
        { name: "Jobs", interUrl: "/jobs" },
        {
          name: "Applications",
          interUrl: "/applications/flow",
        },
        {
          name: "My Tasks",
          interUrl: "/tasks",
        },
        {
          name: "Analysis",
          interUrl: "/analysis",
        },
      ],
    },
    alert: {
      active: false,
      message:
        "Please reference the beta homepage with any questions, comments or feedback you have!",
      link: "https://codingcareerfastlane.notion.site/Job-Leads-Today-Beta-b24bfd235647400d9c932e0de5613ed6",
      linkMessage: "Click Here",
      showToAuthenticatedUsers: true,
    },
  },
}

export default (
  state: SystemDefaultsState = initialSystemDefaultsState,
  action: SystemDefaultsActions,
): SystemDefaultsState => {
  switch (action.type) {
    case STORE_SYSTEM_DEFAULTS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
