import { PublicProfileInterface } from "@relay/interfaces"
import { postPublicProfile } from "../../api-client/apiModules/publicProfile"

export const STORE_PUBLIC_PROFILE = "STORE_PUBLIC_PROFILE" as const

export interface StorePublicProfileAction {
  type: typeof STORE_PUBLIC_PROFILE
  payload: PublicProfileInterface
}

export type PublicProfileActions = StorePublicProfileAction

export const storePublicProfile = (appProfile: PublicProfileInterface) => ({
  type: STORE_PUBLIC_PROFILE,
  payload: appProfile,
})

export const startSavePublicProfile = (profileBody: PublicProfileInterface) => {
  // @ts-expect-error: FIX: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    try {
      const prefs = await postPublicProfile(profileBody)
      dispatch(storePublicProfile(prefs))
    } catch (e) {
      console.error("Error storing PublicProfile.", e)
    }
  }
}
