import {
  OnboardingSteps,
  GroupInterface,
  UserInterface,
  CohortInterface,
  CourseProgressInterface,
  CourseTrackingInterface,
} from "@relay/interfaces"
import {
  UserActions,
  CLEAR_USER_INFO,
  STORE_USER_INFO,
  STORE_USER_FLAGS,
} from "../actions/userActions"
import { couldStartTrivia } from "typescript"

// TODO: we may want to store user token here when auth state changes to reduce number of calls to firebase (currently would be on every auth request)
// Firebase should fire a user not authenticated event when token expires and state will be cleared.  The UI would need conditional logic in place for handling when this occurs
// Tried to use enum from lib but it kept crashing the app, duplicating here, be sure to update both enums if updates are made to roles
export enum UserRolesEnum {
  ADMIN = "Admin",
  GROUPADMIN = "Group Admin",
  COHORTADMIN = "Cohort Admin",
  JOBADMIN = "Job Admin",
  COACH = "Coach",
  COURSEADMIN = "Course Admin",
  SUPERADMIN = "Super Admin",
  SUCCESSCOACH = "Success Coach",
  SUCCESSCOACHMANAGER = "Success Coach Manager",
  RETENTIONCOACH = "Retention Coach",
  CAREERCOACH = "Career Coach",
  DIRECTOROFOUTCOMES = "Director of Outcomes",
  PROGRAMDIRECTOR = "Program Director",
  PROGRAMINSTRUCTOR = "Program Instructor",
  TEACHINGASSISTANT = "Teaching Assistant",
  CAREERCOACHMANAGER = "Career Coach Manager",
  JOBHUNTER = "Job Hunter",
  COURSEACTIVE = "Course Active",
  COURSEINACTIVE = "Course Inactive",
  COURSEPAUSED = "Course Pause",
  PAUSED = "Paused",
  INTROOFFERSTUDENT = "Intro Offer Student",
}

// export enum StaffRolesEnum {
//   ADMIN = 'Admin',
//   GROUPADMIN = 'Group Admin',
//   COHORTADMIN = 'Cohort Admin',
//   JOBADMIN = 'Job Admin',
//   COACH = 'Coach',
//   COURSEADMIN = 'Course Admin',
//   SUPERADMIN = 'Super Admin',
//   SUCCESSCOACH = 'Success Coach',
//   SUCCESSCOACHMANAGER = 'Success Coach Manager',
//   RETENTIONCOACH = 'Retention Coach',
//   CAREERCOACH = 'Career Coach',
//   DIRECTOROFOUTCOMES = 'Director of Outcomes',
//   PROGRAMDIRECTOR = 'Program Director',
//   PROGRAMINSTRUCTOR = 'Program Instructor',
//   TEACHINGASSISTANT = 'Teaching Assistant',
//   CAREERCOACHMANAGER = 'Career Coach Manager',
// }

// export enum StudentRolesEnum {
//   JOBHUNTER = 'Job Hunter',
//   COURSEACTIVE = 'Course Active',
//   COURSEINACTIVE = 'Course Inactive',
//   COURSEPAUSED = 'Course Pause',
//   PAUSED = 'Paused',
//   INTROOFFERSTUDENT = 'Intro Offer Student',
// }

// export const UserRolesEnum = {
//   ...StaffRolesEnum,
//   ...StudentRolesEnum,
// };

// type UserRolesEnum = StaffRolesEnum | StudentRolesEnum;

export interface UserState
  extends Partial<Omit<UserInterface, "firstName" | "lastName">> {
  program: string
  endDate?: string
  dateOfBirth?: Date
  address?: any
  linkedinURL?: string
  hoursPerWeek: any
  courseHistory: any[]
  displayName: string
  email: string
  phoneNumber: string
  photoURL: string
  uid: string
  admin: boolean
  roles: any[]
  active: boolean
  group: string
  groupInfo: GroupInterface | null
  cohort: string
  cohortInfo: CohortInterface | null
  onboarding: OnboardingSteps
  course: string
  courseInfo: {
    courseProgress: CourseProgressInterface | null
    courseTracking?: CourseTrackingInterface | null
  }
  programInfo: {
    programProgress: any
  }
  latest: any
  mentor: string
  slackId: string
  fundingType?: any
  funding?: any
  timeCommitment?: any
  onboardingTasks?: any
  flags?: any
}

export const initialUserState: UserState = {
  address: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string | undefined'.
  endDate: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string | undefined'.
  linkedinURL: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'Date | undefined'.
  dateOfBirth: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  displayName: null,
  hoursPerWeek: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  phoneNumber: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  email: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  uid: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  photoURL: null,
  admin: false,
  roles: [],
  active: false,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  group: null,
  groupInfo: {
    headers: [],
    name: null,
    goals: null,
    resources: [],
    support: [],
    slack: null,
    discord: null,
    email: null,
    photoURL: null,
    site: null,
    computed_feedback: {
      courseId: null,
      lastUpdated: "",
      lessons: {},
    },
    program: {},
  },
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  cohort: null,
  cohortInfo: null,
  onboarding: {
    initial: false,
    resume: false,
    preferences: false,
    settings: false,
  },
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  course: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  program: null,
  courseHistory: [],
  courseInfo: {
    courseProgress: null,
    courseTracking: null,
  },
  programInfo: {
    programProgress: null,
  },
  latest: {
    homework: {
      assignment: null,
      fileUrl: null,
      module: null,
      section: null,
      submittedDate: null,
    },
    interaction: {
      type: null,
      staff: null,
      status: null,
      contact: null,
      date: null,
      notes: null,
    },
    lesson: {
      section: null,
      module: null,
      lesson: null,
      dateCreated: null,
      dateLastUpdated: null,
      completed: false,
    },
  },
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  mentor: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  slackId: null,
  startDate: undefined,
  onboardingTasks: {
    active: false,
    cohort: null,
    group: null,
    courseId: null,
    userId: null,
    completions: {
      completionMessage: null,
      initialMessage: null,
      weekOne: {
        completed: null,
        completedAt: null,
      },
      weekTwo: {
        completed: null,
        completedAt: null,
      },
    },
    weekOne: {
      objectives: {},
      title: null,
    },
    weekTwo: {
      objectives: {},
      title: null,
    },
    weekOneStart: null,
    weekTwoStart: null,
    weekThreeStart: null,
  },
  flags: {},
}

export default (
  state: UserState = initialUserState,
  action: UserActions,
): UserState => {
  switch (action.type) {
    case STORE_USER_INFO:
      return { ...state, ...action.user }
    case CLEAR_USER_INFO:
      return initialUserState
    case STORE_USER_FLAGS:
      return { ...state, flags: action.flags }
    default:
      return state
  }
}
