import {
  ApplicationInterface,
  JobInterface,
  NotificationInterface,
  TaskInterface,
  TriggersInterface,
} from "@relay/interfaces"
import { apiClientWithAuth, apiEndpoints } from ".."
import {
  ApplicationStatus,
  ApplicationType,
} from "../../shared/types/applications"

export interface PostApplicationResponse {
  success: boolean
  message: string
  application: ApplicationInterface
  triggerRecords: {
    notifications: NotificationInterface[]
    tasks: TaskInterface[]
  }
}
export interface AppWithJobInfo {
  application: ApplicationInterface
  job: JobInterface
}

export const fetchAppWithJobInfo = async (
  applicationId: string,
): Promise<AppWithJobInfo> => {
  const res = await apiClientWithAuth.get(`${apiEndpoints.applications}/info`, {
    params: { applicationId },
  })

  return res.data
}

export interface ApplicationsGroupedByJobId {
  [jobId: string]: ApplicationInterface
}
export const fetchApplicationsGroupedByJobId =
  async (): Promise<ApplicationsGroupedByJobId> => {
    const res = await apiClientWithAuth.get(
      `${apiEndpoints.applications}/groupedByJobId`,
    )
    return res.data
  }

type AppBeforeInsert = Omit<ApplicationInterface, "id">
interface CreateApplicationFormRequestBody {
  application: AppBeforeInsert
  descriptionTextToAnalyze: string
}
export const postUserCreatedApplication = async ({
  application,
  descriptionTextToAnalyze,
}: CreateApplicationFormRequestBody): Promise<PostApplicationResponse> => {
  const res = await apiClientWithAuth.post(apiEndpoints.applications, {
    application,
    descriptionTextToAnalyze,
  })
  return res.data
}

export const createApplicationByJobId = async (
  jobId: string,
  applicationType: ApplicationType,
): Promise<PostApplicationResponse> => {
  const url = `${apiEndpoints.applications}?jobLeadId=${jobId}`
  const res = await apiClientWithAuth.post(url, {
    status: ApplicationStatus.APPLIED,
    type: applicationType,
  })

  return res.data
}

export const deleteApplicationByJobId = async (jobId: string): Promise<any> => {
  const url = `${apiEndpoints.applications}/delete/jobId?jobLeadId=${jobId}`
  const res = await apiClientWithAuth.get(url)
  return res.data
}

export const fetchApplications = async (): Promise<ApplicationInterface[]> => {
  const res = await apiClientWithAuth.get(`${apiEndpoints.applications}/all`)

  return res.data
}

export const updateApplication = async (
  applicationId: string,
  updates: Partial<ApplicationInterface>,
) => {
  const res = await apiClientWithAuth.put(
    `${apiEndpoints.applications}/${applicationId}`,
    updates,
  )

  return res.data
}

export interface TriggersFiredResponse {
  tasks: TaskInterface[]
  notifications: NotificationInterface[]
}

export const updateAppStatusWithTriggers = async (
  applicationId: string,
  status: ApplicationStatus,
  triggerEvent: keyof TriggersInterface,
): Promise<TriggersFiredResponse> => {
  const url = `${apiEndpoints.applications}/triggers/${triggerEvent}?applicationId=${applicationId}`

  const res = await apiClientWithAuth.put(url, {
    status,
  })

  return res.data
}

interface DeleteApplicationResponse {
  success: true
  message: "Successfully deleted."
  notesIds: string[]
  interviewIds: string[]
  applicationId: string
}
/**
 * This call deletes all interviews and notes associated with the application on the backend as well as the application record.
 */
export const deleteApplication = async (
  applicationId: string,
): Promise<DeleteApplicationResponse> => {
  const res = await apiClientWithAuth.delete(
    `${apiEndpoints.applications}/${applicationId}`,
  )

  return res.data
}
