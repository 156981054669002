import { environment as prodEnv } from "./environment.prod"
import { environment as devEnv } from "./environment"
import { environmentStrings } from "./utils"

let envVars = devEnv

switch (process.env.NODE_ENV) {
  case environmentStrings.production:
    envVars = prodEnv
    break
  case environmentStrings.development:
    envVars = devEnv
    break
  case environmentStrings.wackyHackyProd: //TODO: remove after resolving prod env issues with css etc.
    envVars = prodEnv
    break
  default:
    envVars = devEnv
}

export default envVars
