import {
  AnalysisInterface,
  InterviewAnalysis,
  TaskAnalysis,
  HistoryAnalysisInterface,
} from "@relay/interfaces"
import dayjs from "dayjs"
import { AnalysisType } from "../../../shared/types/analysis"

export const initAnalysis: AnalysisInterface = {
  bottomSkills: [],
  categories: {},
  history: [],
  jobIds: [],
  jobProviders: {
    linkedin: 0,
    indeed: 0,
    glassdoor: 0,
    dice: 0,
    simplyhired: 0,
    monster: 0,
  },
  jobTitles: [],
  optionalSkills: [],
  powerWordCount: {},
  powerWords: [],
  skills: {},
  topSkills: [],
  total: 0,
  type: "" as AnalysisType,
  types: { hardskill: 0, softskill: 0, verb: 0, adjective: 0 },
  salaries: [],
  breakdownScore: {
    adjectives: [0, 10],
    categories: [0, 10],
    characters: [0, 10],
    hardskill: [0, 10],
    metrics: [0, 10],
    powerwords: [0, 10],
    sentences: [0, 10],
    softskill: [0, 10],
    types: [0, 10],
    verb: [0, 10],
    words: [0, 10],
    wordsize: [0, 10],
    readability: [0, 10],
    detracting: [0, 10],
    email: [0, 10],
    linkedin: [0, 10],
    phone: [0, 10],
  },
  lastUpdated: dayjs().format(),
}

export const initHistoryAnalysis: HistoryAnalysisInterface = {
  active: true,
  name: "",
  startDate: "",
  uid: "",
  type: "",
  cohort: "",
  group: "",
  lastUpdated: "",
  history: {},
  difference: {
    skills: {},
    powerWords: {},
  },
  average: {
    jobQuality: 0,
    resumeQuality: 0,
    skillsMatch: 0,
    powerWordsMatch: 0,
    jobTotalScore: 0,
    resumeTotalScore: 0,
    score: 0,
    tier: 0,
  },
  totals: {
    jobQuality: [],
    resumeQuality: [],
    skillsMatch: [],
    powerWordsMatch: [],
    jobTotalScore: [],
    resumeTotalScore: [],
    score: [],
    tier: [],
  },
}

export const initInterviewAnalysis: InterviewAnalysis = {
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  uid: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  type: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  cohort: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  group: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  lastUpdated: null,
  total: 0,
  history: {},
  types: [],
}

export const initTaskAnalysis: TaskAnalysis = {
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  uid: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  type: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  cohort: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  group: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  lastUpdated: null,
  total: 0,
  history: {},
}
