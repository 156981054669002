import { getFirestore } from "firebase/firestore"
import {
  getAuth,
  signInWithCustomToken,
  signInWithPopup,
  signOut,
} from "firebase/auth"
import { GoogleAuthProvider } from "firebase/auth"
import { initializeApp } from "firebase/app"
import envVars from "../environments/envConfig"

export const firebaseApp = initializeApp(envVars.firebaseConfig)
export const firestore = getFirestore(firebaseApp)

export const getFirebaseUserToken = async () => {
  const auth = getAuth(firebaseApp)
  return auth.currentUser && auth.currentUser.getIdToken(true)
}

export const signInWithGoogle = async () => {
  const googleAuthProvider = new GoogleAuthProvider()
  await signInWithPopup(getAuth(firebaseApp), googleAuthProvider)
}

export const signOutFirebaseUser = async () => {
  return await signOut(getAuth(firebaseApp))
}

/**
 * Signs into Firebase using a custom token - will create a new firebase auth user (not a user record in users collection) if they don't already exist in Firebase.
 * @param customToken a firebase custom token generated on the backend and sent back to client - we pass it in here
 * @returns the new Firebase user or existing user
 */
export const signinToFirebaseWithCustomToken = async (customToken: string) => {
  const auth = getAuth(firebaseApp)
  const userCredential = await signInWithCustomToken(auth, customToken)

  const user = userCredential.user
  return user
}
