import { PreferencesInterface } from "@relay/interfaces"
import { apiClientWithAuth, apiEndpoints } from ".."

export const postPreferences = async (
  preferences: PreferencesInterface,
): Promise<PreferencesInterface> => {
  const res = await apiClientWithAuth.post(
    apiEndpoints.preferences,
    preferences,
  )
  return res.data
}

export const fetchPreferences = async (): Promise<PreferencesInterface> => {
  const res = await apiClientWithAuth.get(apiEndpoints.preferences)
  return res.data
}
