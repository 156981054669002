import { ResumeInterface } from "@relay/interfaces"
import { ResumeActions, STORE_RESUME } from "../actions/resume"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ResumeState extends ResumeInterface {}

export const initialResumeState: ResumeInterface = {
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  lastModifiedDate: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'number'.
  size: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  name: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  resume: null,
  // @ts-expect-error: FIX: Type 'null' is not assignable to type 'string'.
  type: null,
}

export default (
  state: ResumeState = initialResumeState,
  action: ResumeActions,
): ResumeState => {
  switch (action.type) {
    case STORE_RESUME:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
