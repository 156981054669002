import { useEffect, useState } from "react"

const useDimensions = () => {
  const [width, setWidth] = useState(document.body.clientWidth)
  const [height, setHeight] = useState(document.body.clientHeight)

  function handleWindowSizeChange() {
    setWidth(document.body.clientWidth)
    setHeight(document.body.clientHeight)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => window.removeEventListener("resize", handleWindowSizeChange)
  }, [])

  return { width, height }
}

export default useDimensions
