import React from "react"
import { connect } from "react-redux"
import { Route, Redirect, useLocation } from "react-router-dom"
import { StoreState } from "../../redux/reducers"
import { UserState } from "../../redux/reducers/userReducer"
import {
  isInitialOnboardingComplete,
  isInitialOnboardingRoute,
  getInitialOnboardingRoute,
} from "../../util/onboarding"

export interface RouteProps {
  location?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  component?: React.ComponentType<any> | React.ComponentType<any> // eslint-disable-line @typescript-eslint/no-explicit-any
  render?: (props: any) => React.ReactNode // eslint-disable-line @typescript-eslint/no-explicit-any
  children?: ((props: any) => React.ReactNode) | React.ReactNode // eslint-disable-line @typescript-eslint/no-explicit-any
  path?: string | string[]
  exact?: boolean
  sensitive?: boolean
  strict?: boolean
}

interface IMapStateToProps {
  user: UserState
}
type PrivateRouteProps = IMapStateToProps & RouteProps

// Note: isAuthenticated is from the mapStateToProps connection checks the uid for the user stored in redux state -
// this is managed by the `listenToFirebaseAuthState` listener in main.tsx
// @ts-expect-error: FIX: Type '({ user, component: Component, ...rest }: PrivateRouteProps) => JSX.Element' is not assignable to type 'FC<RouteProps>'. Types of parameters '__0' and 'props' are incompatible. Type 'RouteProps' is not assignable to type 'PrivateRouteProps'. Property 'user' is missing in type 'RouteProps' but required in type 'IMapStateToProps'.
const PrivateRoute: React.FC<RouteProps> = ({
  user,
  component: Component,
  ...rest
}: PrivateRouteProps) => {
  const isAuthenticated = !!user.uid
  const userIsActive = user.active

  const initialOnboardingComplete = isInitialOnboardingComplete(user.onboarding)
  const isAuthorized = isAuthenticated && userIsActive

  const location = useLocation()
  const currentRoute = location.pathname

  const userAuthorizedButOnboardingIncomplete =
    isAuthorized &&
    !initialOnboardingComplete &&
    !isInitialOnboardingRoute(currentRoute)

  if (userAuthorizedButOnboardingIncomplete) {
    return (
      <Route
        {...rest}
        // @ts-expect-error: FIX: Parameter 'props' implicitly has an 'any' type.
        component={(props) => <Redirect to={getInitialOnboardingRoute()} />}
      />
    )
  }

  return (
    <Route
      {...rest}
      // @ts-expect-error: FIX: Parameter 'props' implicitly has an 'any' type.
      component={(props) =>
        // @ts-expect-error: FIX: Multiple errors, uncomment to see.
        isAuthorized ? <Component {...props} /> : <Redirect to={"/login"} />
      }
    />
  )
}
const mapStateToProps = (state: StoreState) => ({
  user: state.user,
})

export default connect(mapStateToProps)(PrivateRoute)
