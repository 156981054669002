import { PublicProfileInterface } from "@relay/interfaces"
import {
  PublicProfileActions,
  STORE_PUBLIC_PROFILE,
} from "../actions/publicProfile"

export const initialPublicProfileState: PublicProfileInterface = {
  about: "",
  photoUrl: "",
  coverPhotoUrl: "",
  username: "",
}
export type PublicProfileState = typeof initialPublicProfileState

export default (
  state: PublicProfileState = initialPublicProfileState,
  action: PublicProfileActions,
): PublicProfileState => {
  switch (action.type) {
    case STORE_PUBLIC_PROFILE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
