import { AnalysisInterface, ResumeInterface } from "@relay/interfaces"
import { postResume } from "../../api-client/apiModules/resume"
import { storeAnalysis } from "./analysis"

export const STORE_RESUME = "STORE_RESUME" as const

export interface StoreResumeAction {
  type: typeof STORE_RESUME
  payload: ResumeInterface
}

export type ResumeActions = StoreResumeAction

export const storeResume = (resume: Partial<ResumeInterface>) => ({
  type: STORE_RESUME,
  payload: resume,
})

export const startSaveResume = (
  resume: ResumeInterface,
  storedResumeAnalysis: AnalysisInterface,
) => {
  // @ts-expect-error: FIX: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    try {
      const res = await postResume(resume)
      dispatch(storeResume(resume))
      dispatch(
        storeAnalysis({ resume: { ...storedResumeAnalysis, ...res.analysis } }),
      )
    } catch (e) {
      console.error("Error saving resume: ", e)
    }
  }
}
