import {
  AnnouncementInterface,
  ApplicationProfileInterface,
  FAQInterface,
  GroupInterface,
  InterviewInterface,
  OnboardingSteps,
  PartnerJobInterface,
  PreferencesInterface,
  ResumeInterface,
  TaskInterface,
  TriggersInterface,
  UserInterface,
  CodingProblemInterface,
} from "@relay/interfaces"
import { apiClientWithAuth, apiEndpoints } from ".."
import { AnalysisState } from "../../redux/reducers/analysisReducer/analysis"
import { ApplicationsGroupedByJobId } from "./applications"
import { SystemDefaultsResponse } from "./system"

export interface FetchedUserResponse
  extends Omit<UserInterface, "firstName" | "lastName"> {
  id: string
  program: string
  programInfo: { programProgress: any }
  endDate?: string
  onboardingTasks?: any
  meetingsUrl?: string
  cohortInfo: any
  groupInfo: GroupInterface
  firstName?: string
  lastName?: string
  displayName?: string
  course?: any
  courseInfo?: any
  courseHistory?: any
  latest?: any
  mentor?: string
  roles: any
  hiredInfo: any
  slackId: string
  fundingType?: any
  funding?: any
  timeCommitment?: any
  hoursPerWeek?: string
  tuitionPaymentType?: string
}
interface FetchUserArgs {
  id?: string
}

export const fetchAllUserData = async (uid: string): Promise<any> => {
  const res = await apiClientWithAuth.get(`${apiEndpoints.userData}/${uid}`)
  return res.data
}

export const fetchUser = async (
  options?: FetchUserArgs,
): Promise<FetchedUserResponse> => {
  let params = {}
  if (options?.id) params = { id: options.id }
  const res = await apiClientWithAuth.get(apiEndpoints.user, {
    params,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    withCredentials: false,
  })
  return res.data
}

interface UpdateUserResponse {
  success: boolean
  message: string
}
export const updateUser = async (
  updates: Partial<UserInterface>,
): Promise<UpdateUserResponse> => {
  const res = await apiClientWithAuth.put(apiEndpoints.user, updates)
  return res.data
}

export const updateOnboardingStep = async (
  step: Record<string, boolean>,
  userOnboarding: OnboardingSteps,
) => {
  return await updateUser({
    onboarding: {
      ...userOnboarding,
      ...step,
    },
  })
}

// @ts-expect-error: FIX: Multiple errors, uncomment to see.
export const updateUserName = async ({ firstName, lastName, hoursPerWeek }) => {
  const res = await apiClientWithAuth.put(`${apiEndpoints.user}/name/hours`, {
    firstName,
    lastName,
    hoursPerWeek,
  })
  return res.data
}

interface LoadRelatedUserDataResponse {
  preferences: PreferencesInterface
  resume: ResumeInterface
  allAnalyses: AnalysisState
  appProfile: ApplicationProfileInterface
  triggers: TriggersInterface
  systemDefaults: SystemDefaultsResponse
  interviews: InterviewInterface[] // TODO: This is not right - there is an application being tacked onto the interview in the get interviews endpoint, going to be some work redoing the typing throughout the app for state
  applicationsGroupedByJobId: ApplicationsGroupedByJobId
}
export const fetchRelatedUserData = async (
  uid: string,
): Promise<LoadRelatedUserDataResponse> => {
  const res = await apiClientWithAuth.get(`${apiEndpoints.userData}/${uid}`)
  return res.data
}

interface HomeDataResponse {
  announcements: AnnouncementInterface[]
  faq: FAQInterface[]
  recruiters: any[]
  tasks: TaskInterface[]
  partnerJobs: PartnerJobInterface[]
  codingProblems: CodingProblemInterface[]
}
export const fetchHomePageData = async (): Promise<HomeDataResponse> => {
  const res = await apiClientWithAuth.get(`${apiEndpoints.user}/home`)
  return res.data
}
export const changeUserCourse = async (student: any): Promise<any> => {
  const res = await apiClientWithAuth.put<any>(`${apiEndpoints.user}/course`, {
    ...student,
  })
  return res.data
}

export const getAllUsers = async (data?: any): Promise<any> => {
  const res = await apiClientWithAuth.get(`${apiEndpoints.coach}/users/all`, {
    params: data || {},
  })
  return res.data
}

export const getCoach = async (userId: string): Promise<any> => {
  const res = await apiClientWithAuth.get(
    `${apiEndpoints.user}/coach/${userId}`,
  )
  return res.data
}
