const ACTIVE = "active"
const IDLE = "idle"

const DEFAULT_INITIAL_STATE = ACTIVE

const DEFAULT_ACTIVITY_EVENTS = [
  "click",
  "mousemove",
  "keydown",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "focus",
]

// @ts-expect-error: FIX: Variable 'DEFAULT_INACTIVITY_EVENTS' implicitly has type 'any[]' in some locations where its type cannot be determined.
const DEFAULT_INACTIVITY_EVENTS = [] // 'blur', 'visibilitychange'

// @ts-expect-error: FIX: Variable 'DEFAULT_IGNORED_EVENTS_WHEN_IDLE' implicitly has type 'any[]' in some locations where its type cannot be determined.
const DEFAULT_IGNORED_EVENTS_WHEN_IDLE = [] // 'mousemove'

/**
 * Creates an activity detector instance
 *
 * @param  {Object}   options
 * @param  {string[]} options.activityEvents        Events which force a transition to 'active'
 * @param  {string[]} options.inactivityEvents      Events which force a transition to 'idle'
 * @param  {string[]} options.ignoredEventsWhenIdle Events that are ignored in 'idle' state
 * @param  {number}   options.timeToIdle            Inactivity time in ms to transition to 'idle'
 * @param  {string}   options.initialState          One of 'active' or 'idle'
 * @param  {boolean}  options.autoInit
 * @return {Object}   activity detector instance
 */
const activityDetector = ({
  activityEvents = DEFAULT_ACTIVITY_EVENTS,
  // @ts-expect-error: FIX: Variable 'DEFAULT_INACTIVITY_EVENTS' implicitly has an 'any[]' type.
  inactivityEvents = DEFAULT_INACTIVITY_EVENTS,
  // @ts-expect-error: FIX: Variable 'DEFAULT_IGNORED_EVENTS_WHEN_IDLE' implicitly has an 'any[]' type.
  ignoredEventsWhenIdle = DEFAULT_IGNORED_EVENTS_WHEN_IDLE,
  timeToIdle = 1800000,
  initialState = DEFAULT_INITIAL_STATE,
  autoInit = true,
} = {}) => {
  let hidden, visibilityChangeEvent
  if (typeof document.hidden !== "undefined") {
    hidden = "hidden"
    visibilityChangeEvent = "visibilitychange"
  } else {
    const prefixes = ["webkit", "moz", "ms"]
    for (let i = 0; i < prefixes.length; i++) {
      const prefix = prefixes[i]
      // @ts-expect-error: FIX: Element implicitly has an 'any' type because expression of type '`${string}Hidden`' can't be used to index type 'Document'.
      if (typeof document[`${prefix}Hidden`] !== "undefined") {
        hidden = `${prefix}Hidden`
        visibilityChangeEvent = `${prefix}visibilitychange`
        break
      }
    }
  }

  const listeners = { [ACTIVE]: [], [IDLE]: [] }
  // @ts-expect-error: FIX: Variable 'state' implicitly has type 'any' in some locations where its type cannot be determined.
  let state
  // @ts-expect-error: FIX: Variable 'timer' implicitly has type 'any' in some locations where its type cannot be determined.
  let timer

  // @ts-expect-error: FIX: Parameter 'newState' implicitly has an 'any' type.
  const setState = (newState) => {
    // @ts-expect-error: FIX: Variable 'timer' implicitly has an 'any' type.
    clearInterval(timer)
    if (newState === ACTIVE) {
      timer = setInterval(() => {
        setState(IDLE)
      }, timeToIdle)
    }
    // @ts-expect-error: FIX: Variable 'state' implicitly has an 'any' type.
    if (state !== newState) {
      state = newState
      // @ts-expect-error: FIX: Multiple errors, uncomment to see.
      listeners[state].forEach((l) => l())
    }
  }

  // @ts-expect-error: FIX: Parameter 'event' implicitly has an 'any' type.
  const handleUserActivityEvent = (event) => {
    // @ts-expect-error: FIX: Variable 'state' implicitly has an 'any' type.
    if (state === ACTIVE || ignoredEventsWhenIdle.indexOf(event.type) < 0) {
      setState(ACTIVE)
    }
  }

  // const handleUserInactivityEvent = () => {
  //     setState(IDLE);
  // };

  // const handleVisibilityChangeEvent = () => {
  //     setState(document[hidden] ? IDLE : ACTIVE);
  // };

  /**
   * Starts the activity detector with the given state.
   * @param {string} firstState 'idle' or 'active'
   */
  const init = (firstState = DEFAULT_INITIAL_STATE) => {
    setState(firstState === ACTIVE ? ACTIVE : IDLE)
    activityEvents.forEach((eventName) =>
      window.addEventListener(eventName, handleUserActivityEvent),
    )

    // inactivityEvents.filter(eventName => eventName !== 'visibilitychange')
    //     .forEach(eventName =>
    //         window.addEventListener(eventName, handleUserInactivityEvent));

    // if (inactivityEvents.indexOf('visibilitychange') >= 0 && visibilityChangeEvent) {
    //     document.addEventListener(visibilityChangeEvent, handleVisibilityChangeEvent);
    // }
  }

  /**
   * Register an event listener for the required event
   * @param {string} eventName 'active' or 'idle'
   * @param {Function} listener
   */
  // @ts-expect-error: FIX: Multiple errors, uncomment to see.
  const on = (eventName, listener) => {
    // @ts-expect-error: FIX: Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ active: never[]; idle: never[]; }'.
    listeners[eventName].push(listener)
    const off = () => {
      // @ts-expect-error: FIX: Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ active: never[]; idle: never[]; }'.
      const index = listeners[eventName].indexOf(listener)
      if (index >= 0) {
        // @ts-expect-error: FIX: Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ active: never[]; idle: never[]; }'.
        listeners[eventName].splice(index, 1)
      }
    }
    return off
  }

  /**
   * Stops the activity detector and clean the listeners
   */
  const stop = () => {
    listeners[ACTIVE] = []
    listeners[IDLE] = []

    // @ts-expect-error: FIX: Variable 'timer' implicitly has an 'any' type.
    clearInterval(timer)

    activityEvents.forEach((eventName) =>
      window.removeEventListener(eventName, handleUserActivityEvent),
    )

    // inactivityEvents.forEach(eventName =>
    //     window.removeEventListener(eventName, handleUserInactivityEvent));

    // if (visibilityChangeEvent) {
    //     document.removeEventListener(visibilityChangeEvent, handleVisibilityChangeEvent);
    // }
  }

  if (autoInit) {
    init(initialState)
  }

  return { on, stop, init }
}

export default activityDetector
