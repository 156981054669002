import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid"
import React, { Fragment, useState } from "react"
import { NavLink } from "react-router-dom"
import { Menu, Transition } from "@headlessui/react"
import { useBreakpoints } from "../../../../shared/customHooks/useBreakpoints"
import "./dropdown.scss"

interface DropdownOption {
  name: string
  link: string
}

interface DropdownProps {
  options: DropdownOption[]
  label: string
  onClick?: () => void
  active: boolean
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  label,
  onClick,
  active,
}) => {
  const { isSmallScreen } = useBreakpoints()

  return (
    <Menu as="div" className="nav-dropdown">
      {({ open }) => {
        return (
          <>
            <div>
              <Menu.Button
                className={`menu-button ${active ? "--active" : ""}`}
                onClick={onClick}
              >
                {label}
                {isSmallScreen ? (
                  <ChevronRightIcon
                    className="-mr-1 h-6 w-6"
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronDownIcon
                    className="-mr-1 h-6 w-6"
                    aria-hidden="true"
                  />
                )}
              </Menu.Button>
            </div>
            {open && (
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  style={{
                    border: "1px solid #8D89A7",
                    left: `${isSmallScreen ? "51%" : ""}`,
                  }}
                  className={
                    isSmallScreen
                      ? `absolute top-0 z-10 w-56 origin-top-right rounded-lg bg-white focus:outline-none`
                      : `absolute left-0 z-10 w-56 origin-top-right rounded-lg bg-white py-2 focus:outline-none`
                  }
                >
                  <div>
                    {options.map((option) => (
                      <Menu.Item key={option.name}>
                        {({ active }) => {
                          return (
                            <NavLink
                              exact
                              to={option.link}
                              className="flex flex-col items-start px-4 py-2 hover:border-l-2 hover:border-l-purple-1150 hover:bg-purple-1050"
                              role="menuitem"
                            >
                              <p>{option.name}</p>
                            </NavLink>
                          )
                        }}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            )}
          </>
        )
      }}
    </Menu>
  )
}

export default Dropdown
