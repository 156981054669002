import { Route } from "react-router-dom"
import { Switch, useHistory } from "react-router-dom"
import { trackPage } from "../../util/integrations"
import { useEffect, lazy, Suspense } from "react"
import { useSelector } from "react-redux"
import "./router.module.scss"

import LoadingOverlay from "../../components/loadingOverlay/LoadingOverlay"
import PrivateRoute from "./privateroute"
import { StoreState } from "../../redux/reducers"
import { routes } from "./routes"

const AdminDashboard = lazy(() => import("../admin-dashboard/admin-dashboard"))
const AdminHome = lazy(() =>
  import("../admin/admin-home").then((module) => ({
    default: module.AdminHome,
  })),
)
const AdminJobSearchDashboard = lazy(
  () => import("../admin-job-search-dashboard/AdminJobSearchDashboard"),
)
const AdminOnboardingDashboard = lazy(() =>
  import("../admin-onboarding-dashboard/AdminOnboardingDashboard").then(
    (module) => ({ default: module.AdminOnboardingDashboard }),
  ),
)
const AdminProgressLearning = lazy(() =>
  import("../admin-progress-learning/AdminProgressLearning").then((module) => ({
    default: module.AdminProgressLearning,
  })),
)
const AdminSearches = lazy(() => import("../admin-dashboard/admin-searches"))
const AnalysisPage = lazy(() => import("../analysis-page/AnalysisPage"))
const ApplicationAnalysis = lazy(
  () => import("../application-analysis/application-analysis"),
)
const Applications = lazy(() => import("../applications/applications"))
const ApplicationsFlow = lazy(
  () => import("../applicationsFlow/applicationsFlow"),
)
const Beta = lazy(() => import("../beta/beta"))
const CareerCoachDashboard = lazy(
  () => import("../career-coach/CareerCoachDashboard"),
)
const CareerCoachManagerDashboard = lazy(() =>
  import("../career-coach-manager/CareerCoachManagerDashboard").then(
    (module) => ({ default: module.CareerCoachManagerDashboard }),
  ),
)
const CareerStudentDashboard = lazy(
  () => import("../career-coach/career-student-dashboard/StudentDashboard"),
)
const CoachCohortDashboard = lazy(
  () => import("../coach-dashboards/cohort-dashboard/CoachCohortDashboard"),
)
const CoachCourseDashboard = lazy(
  () => import("../coach-course-dashboard/CoachCourseDashboard"),
)
const CoachGroupDashboard = lazy(
  () => import("../coach-dashboards/group-dashboard/CoachGroupDashboard"),
)
const ContentEditor = lazy(() => import("../content/content-editor"))
const CourseDashboard = lazy(() =>
  import("../courseV2/CourseDashboard").then((module) => ({
    default: module.CourseDashboard,
  })),
)
const CustomTriggers = lazy(() => import("../customTriggers/CustomTriggers"))
const Dashboard = lazy(() => import("../dashboard/dashboard"))
const DirectorOfOutcomesDashboard = lazy(
  () => import("../director-of-outcomes/DirectorOfOutcomesDashboard"),
)
const DisplayPage = lazy(() => import("../learning-library/DisplayPage"))
const EditStudentProfile = lazy(() =>
  import("../user-management-page/StudentManagement/EditStudentProfile").then(
    (module) => ({ default: module.EditStudentProfile }),
  ),
)
const EditYourAccount = lazy(() =>
  import("../your-account/EditYourAccount").then((module) => ({
    default: module.EditYourAccount,
  })),
)
const EducationDashboard = lazy(() =>
  import("../education-dashboard/EducationDashboard").then((module) => ({
    default: module.EducationDashboard,
  })),
)
const Errors = lazy(() =>
  import("../admin/admin-errors").then((module) => ({
    default: module.Errors,
  })),
)
const GroupDashboard = lazy(
  () => import("../admin-dashboard/groupsAndCohorts/group-dashboard"),
)
const Home = lazy(() =>
  import("../home/home").then((module) => ({ default: module.Home })),
)
const HomeworkSubmissionPage = lazy(() =>
  import("../submissions/HomeworkSubmissionPage").then((module) => ({
    default: module.HomeworkSubmissionPage,
  })),
)
const InteractionPage = lazy(() => import("../interaction/InteractionsPage"))
const Interviews = lazy(() => import("../interviews/interviews"))
const JobQueue = lazy(() => import("../jobQueueV2/jobQueue"))
const Jobs = lazy(() => import("../jobs/jobs"))
const JobsV2 = lazy(() => import("../jobsV2/jobs"))
const LearningActivityDashboard = lazy(
  () => import("../learningActivity/learning-activity-dashboard"),
)
const LearningLibraryWorkshops = lazy(() =>
  import("../learning-library/LearningLibraryWorkshops").then((module) => ({
    default: module.LearningLibraryWorkshops,
  })),
)
const LinkedInCallback = lazy(() =>
  import("react-linkedin-login-oauth2").then((module) => ({
    default: module.LinkedInCallback,
  })),
)
const Login = lazy(() => import("../authentication/login/login"))
const Outreach = lazy(() => import("../outreach/outreach"))
const Preferences = lazy(() => import("../preferences/preferences"))
const Profile = lazy(() => import("../profile/profile"))
const RetentionCoachDashboard = lazy(() =>
  import("../retention-coach/RetentionCoachDashboard").then((module) => ({
    default: module.RetentionCoachDashboard,
  })),
)
const ReviewReportedJobs = lazy(
  () => import("../reviewReportedJobs/reviewReportedJobs"),
)
const RichText = lazy(() => import("../content/rich-text"))
const SearchDashboard = lazy(() =>
  import("../admin/search-dashboard").then((module) => ({
    default: module.SearchDashboard,
  })),
)
const Settings = lazy(() => import("../settings/settings"))
const StudentDashboard = lazy(
  () => import("../coach-dashboards/student-dashboard/StudentDashboard"),
)
const StudentManagementDashboard = lazy(() =>
  import(
    "../user-management-page/StudentManagement/StudentManagementDashboard"
  ).then((module) => ({ default: module.StudentManagementDashboard })),
)
const SuccessCoachDashboard = lazy(
  () => import("../success-coach/SuccessCoachDashboard"),
)
const SuccessCoachManagerDashboard = lazy(() =>
  import("../success-coach-manager/SuccessCoachManagerDashboard").then(
    (module) => ({ default: module.SuccessCoachManagerDashboard }),
  ),
)
const SuccessStudentDashboard = lazy(
  () => import("../success-coach/student-dashboard/SuccessStudentDashboard"),
)
const SuperAdminDashboard = lazy(
  () => import("../super-admin/SuperAdminDashboard"),
)
const TasksPageContainer = lazy(() => import("../tasks/TasksPageContainer"))
const Tools = lazy(() => import("../user-tools/tools"))
const UserDashboard = lazy(() => import("../user-dashboard/user-dashboard"))
const UserManagementPage = lazy(
  () => import("../user-management-page/UserManagementPage"),
)
const UserSearch = lazy(() => import("../user-search/user-search"))
const UserSystemTrackingDashboard = lazy(() =>
  import("../user-system-tracking/UserSystemTrackingDashboard").then(
    (module) => ({ default: module.UserSystemTrackingDashboard }),
  ),
)
const YourAccount = lazy(() =>
  import("../your-account/YourAccount").then((module) => ({
    default: module.YourAccount,
  })),
)

export function Router() {
  const history = useHistory()
  const user = useSelector((state: StoreState) => state.user)
  useEffect(() => {
    history.listen(trackPage)
  }, [history])

  return (
    <Suspense
      fallback={
        <LoadingOverlay loadingMessage="Loading your learning platform..." />
      }
    >
      <Switch>
        <Route path="/login/invite/:accessToken" exact component={Login} />
        <Route path="/login" exact component={Login} />
        <Route
          path={routes.linkedInOAuthRedirect}
          exact
          component={LinkedInCallback}
        />
        {/* <Route path="/pricing" exact component={Pricing} />
      <Route path="/testimonial" exact component={Testimonial} /> */}
        <Route path="/beta" exact component={Beta} />

        {user.roles.includes("Admin") ? (
          <PrivateRoute path="/" exact component={AdminHome} />
        ) : (
          <PrivateRoute path="/" exact component={Home} />
        )}
        {user.roles.includes("Admin") && (
          <PrivateRoute
            path={`${routes.adminOnboardingDashboard}`}
            exact
            component={AdminOnboardingDashboard}
          />
        )}
        {user.roles.includes("Admin") && (
          <PrivateRoute
            path="/admin/learning/:studentId?"
            exact
            component={AdminProgressLearning}
          />
        )}
        {user.roles.includes("Admin") && (
          <PrivateRoute
            path="/admin/job-search"
            exact
            component={AdminJobSearchDashboard}
          />
        )}

        {(user.flags?.course_access === "on" ||
          (user.roles.includes("Admin") &&
            user.flags?.course_admin === "on")) && (
          <PrivateRoute
            path={routes.course}
            exact
            component={CourseDashboard}
          />
        )}
        <PrivateRoute
          path={`/learning/level/:levelId`}
          exact
          component={LearningActivityDashboard}
        />
        <PrivateRoute
          path={"/workshops"}
          exact
          component={LearningLibraryWorkshops}
        />
        <PrivateRoute
          path={"/workshops/:workshopTitle/:workshopId"}
          component={DisplayPage}
        />
        <PrivateRoute path="/jobs" exact component={JobsV2} />
        <PrivateRoute path="/jobs/legacy" exact component={Jobs} />
        <PrivateRoute
          path={routes.reportedJobs}
          exact
          component={ReviewReportedJobs}
        />
        <PrivateRoute path="/dashboard" exact component={UserDashboard} />
        <PrivateRoute path={routes.preferences} exact component={Preferences} />
        <PrivateRoute path={routes.settings} exact component={Settings} />
        <PrivateRoute path={routes.yourAccount} exact component={YourAccount} />

        <PrivateRoute path={routes.profile} exact component={YourAccount} />

        <PrivateRoute
          path={routes.profileEdit}
          exact
          component={EditYourAccount}
        />
        <PrivateRoute path="/applications" exact component={Applications} />
        <PrivateRoute path="/interviews" exact component={Interviews} />
        <PrivateRoute path="/outreach" exact component={Outreach} />
        <PrivateRoute path="/tools" exact component={Tools} />
        <PrivateRoute path="/job-queue" exact component={JobQueue} />
        <PrivateRoute path="/user-search" exact component={UserSearch} />
        <PrivateRoute path="/admin-searches" exact component={AdminSearches} />
        <PrivateRoute
          path="/admin-dashboard"
          exact
          component={AdminDashboard}
        />
        <PrivateRoute
          path={`${routes.coachCohortDashboard}/:cohortId`}
          exact
          component={CoachCohortDashboard}
        />
        <PrivateRoute
          path="/groups-dashboard"
          exact
          component={GroupDashboard}
        />
        <PrivateRoute
          path={routes.applicationsFlow}
          exact
          component={ApplicationsFlow}
        />
        <PrivateRoute
          path="/content/:group/:content"
          exact
          component={RichText}
        />
        <PrivateRoute path="/user-data-dashboard" exact component={Dashboard} />
        <PrivateRoute path="/errors" exact component={Errors} />
        <PrivateRoute
          path={routes.customTriggers}
          exact
          component={CustomTriggers}
        />
        <PrivateRoute path="/content-editor" exact component={ContentEditor} />
        <PrivateRoute
          path="/search-dashboard"
          exact
          component={SearchDashboard}
        />
        <PrivateRoute
          path="/application-analysis"
          exact
          component={ApplicationAnalysis}
        />
        <PrivateRoute
          path={routes.coachGroupDashboard}
          exact
          component={CoachGroupDashboard}
        />
        <PrivateRoute
          path={`${routes.coachGroupDashboard}/cohort/:cohortId/student/:studentId`}
          exact
          component={StudentDashboard}
        />
        <PrivateRoute
          path={`${routes.coachGroupDashboard}/career/cohort/:cohortId/student/:studentId`}
          exact
          component={CareerStudentDashboard}
        />
        <PrivateRoute
          path={`${routes.coachGroupDashboard}/success/cohort/:cohortId/student/:studentId`}
          exact
          component={SuccessStudentDashboard}
        />
        <PrivateRoute
          path={routes.coachCourseDashboard}
          exact
          component={CoachCourseDashboard}
        />
        <PrivateRoute
          path={routes.tasks}
          exact
          component={TasksPageContainer}
        />
        <PrivateRoute path={routes.analysis} exact component={AnalysisPage} />
        <PrivateRoute
          path={`${routes.submission}/:type/:section/:module/:assignment`}
          exact
          component={HomeworkSubmissionPage}
        />
        <PrivateRoute
          path={`${routes.successCoachDashboard}`}
          exact
          component={SuccessCoachDashboard}
        />
        <PrivateRoute
          path={`${routes.successCoachManagerDashboard}`}
          exact
          component={SuccessCoachManagerDashboard}
        />
        <PrivateRoute
          path={`${routes.careerCoachManagerDashboard}`}
          exact
          component={CareerCoachManagerDashboard}
        />
        <PrivateRoute
          path={`${routes.retentionCoachDashboard}`}
          exact
          component={RetentionCoachDashboard}
        />
        <PrivateRoute
          path={`${routes.careerCoachDashboard}`}
          exact
          component={CareerCoachDashboard}
        />
        <PrivateRoute
          path={`${routes.directorOfOutcomesDashboard}`}
          exact
          component={DirectorOfOutcomesDashboard}
        />
        <PrivateRoute
          path={`${routes.educationDashboard}`}
          exact
          component={EducationDashboard}
        />
        <PrivateRoute
          path={`${routes.userSystemTracking}`}
          exact
          component={UserSystemTrackingDashboard}
        />
        <PrivateRoute
          path={`${routes.userManagement}`}
          exact
          component={UserManagementPage}
        />
        <PrivateRoute
          path={`${routes.userManagement}/student/:studentId`}
          exact
          component={StudentManagementDashboard}
        />
        <PrivateRoute
          path={`${routes.userManagement}/student/profile/:studentId`}
          exact
          component={EditStudentProfile}
        />
        {/* <PrivateRoute
        path={`${routes.interaction}`}  // old page that no longer has correct form data
        exact
        component={InteractionPage}
      /> */}
        {/* <PrivateRoute path={`${routes.course}`} exact component={CoursePage} /> */}
        <PrivateRoute
          path={`${routes.superAdmin}`}
          exact
          component={SuperAdminDashboard}
        />
        {/* THIS ROUTE IS TO SEND USERS TO LOGIN PAGE IF THEY TRY TO ACCESS A PAGE THAT DOES NOT EXIST */}
        <PrivateRoute path={`/:anything`} component={Login} />
      </Switch>
    </Suspense>
  )
}

export default Router
