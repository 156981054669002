import React from "react"

interface Props {
  loadingMessage?: string
  backgroundColorHex?: string
  textColorHex?: string
  error?: boolean
}
const LoadingOverlay: React.FC<Props> = ({
  loadingMessage,
  textColorHex = undefined,
  backgroundColorHex = undefined,
  error = false,
}) => {
  const styles = {
    backgroundColor: backgroundColorHex || undefined,
    color: textColorHex || undefined,
  }
  return (
    <div
      className={`fixed bottom-0 left-0 right-0 top-0 z-50 flex h-screen w-full flex-col items-center justify-center overflow-hidden opacity-50 ${
        backgroundColorHex ? "" : "bg-gray-700"
      }`}
      style={styles}
    >
      <div
        className={`${
          !error ? "loader ease-linear" : "loader"
        } mb-4 h-12 w-12 rounded-full border-4 border-t-4 border-gray-200`}
      ></div>

      <h2
        id="loading-overlay-heading"
        className={`text-center text-xl font-semibold ${
          textColorHex ? "" : "text-white"
        } `}
      >
        {error ? "Error" : "Loading V School..."}
      </h2>
      {loadingMessage && (
        <p className="w-1/3 text-center text-white">{loadingMessage}</p>
      )}
    </div>
  )
}

export default LoadingOverlay
