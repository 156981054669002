/**
 *  use npm script for setting local dev - `npm run start:local`
 *  NODE_ENV will be 'development' when on test host site or running 'npm start'
 */

const API_VERSION = "v0"

export const environmentStrings = {
  local: "local" as const,
  development: "development" as const,
  production: "production" as const,
  wackyHackyProd: "wackyHackyProd" as const, //TODO: remove after resolving prod env issues with css etc.
}

export const environmentURI = {
  default: "jobleadstodaytest",
  beta: "codingcareerfastlane-jobhacker",
  development: "jobleadstodaytest",
  test: "jobleadstodaytest",
  feature: "jobleadstodayfeature",
  production: "jobleadstodayprod",
  local: "jobleadstodaytest",
}

export const getBaseApiUrlForEnv = (): string => {
  let baseURL
  if (window.location.hostname !== "localhost") {
    baseURL = `${window.location.origin}/api/v0`
  } else {
    baseURL = `http://localhost:5001/${
      // @ts-expect-error: FIX: Type 'undefined' cannot be used as an index type.
      environmentURI[process.env.NODE_ENV]
    }/us-central1/api${API_VERSION}/api/${API_VERSION}`
  }

  return baseURL
}

export const getFirebaseConfig = (env: keyof typeof environmentStrings) => {
  switch (env) {
    case environmentStrings.local:
      return devFirebaseConfig
    case environmentStrings.development:
      return devFirebaseConfig
    case environmentStrings.production:
      return prodFirebaseConfig
    case environmentStrings.wackyHackyProd: //TODO: remove after resolving prod env issues with css etc.
      return prodFirebaseConfig
    default:
      return devFirebaseConfig
  }
}
export const getSplitConfig = (env: keyof typeof environmentStrings) => {
  switch (env) {
    case environmentStrings.local:
      return "g3p41pmk201rjvb7b9vca2qeu0ab8po7463h"
    case environmentStrings.development:
      return "g3p41pmk201rjvb7b9vca2qeu0ab8po7463h"
    case environmentStrings.production:
      return "i8d28jrjtjv1m9fbua90klagfqpbs1n39fn1"
    case environmentStrings.wackyHackyProd: //TODO: remove after resolving prod env issues with css etc.
      return "i8d28jrjtjv1m9fbua90klagfqpbs1n39fn1"
    default:
      return "g3p41pmk201rjvb7b9vca2qeu0ab8po7463h"
  }
}

const prodFirebaseConfig = {
  apiKey: "AIzaSyBDwjZ-6nyV2knyv3HVw0ooh1-CSn1UFlA",
  authDomain: "jobleadstodayprod.firebaseapp.com",
  databaseURL: "https://jobleadstodayprod-default-rtdb.firebaseio.com",
  projectId: "jobleadstodayprod",
  storageBucket: "jobleadstodayprod.appspot.com",
  messagingSenderId: "183373726870",
  appId: "1:183373726870:web:c7e2dd0272d3da1e08aeb7",
  measurementId: "G-Z2XYK84EX0",
}

const devFirebaseConfig = {
  apiKey: "AIzaSyCTzGrHF13J2flOC_R-_J1YtlV-GYWDkkI",
  authDomain: "jobleadstodaytest.firebaseapp.com",
  databaseURL: "https://jobleadstodaytest-default-rtdb.firebaseio.com",
  projectId: "jobleadstodaytest",
  storageBucket: "jobleadstodaytest.appspot.com",
  messagingSenderId: "1056980942533",
  appId: "1:1056980942533:web:88d253d7534b94522b7aac",
  measurementId: "G-L81NBXX4Y4",
}
