import { ApplicationProfileInterface } from "@relay/interfaces"
import { apiClientWithAuth, apiEndpoints } from ".."

export const postApplicationProfile = async (
  appProfile: ApplicationProfileInterface,
): Promise<ApplicationProfileInterface> => {
  const res = await apiClientWithAuth.post(
    apiEndpoints.applicationProfile,
    appProfile,
  )
  return res.data
}

export const fetchApplicationProfile =
  async (): Promise<ApplicationProfileInterface> => {
    const res = await apiClientWithAuth.get(apiEndpoints.applicationProfile)
    return res.data
  }
