import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import rootReducer from "./reducers"
import { composeWithDevTools } from "redux-devtools-extension"
import LogRocket from "logrocket"

const composeEnhancers = composeWithDevTools({})

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, LogRocket.reduxMiddleware())),
)

export default store
