import { PublicProfileInterface } from "@relay/interfaces"
import { apiClientWithAuth, apiEndpoints } from ".."

export const postPublicProfile = async (
  publicProfile: PublicProfileInterface,
): Promise<PublicProfileInterface> => {
  const res = await apiClientWithAuth.post(
    apiEndpoints.publicProfile,
    publicProfile,
  )
  return res.data
}

export const fetchPublicProfile = async (): Promise<PublicProfileInterface> => {
  const res = await apiClientWithAuth.get(apiEndpoints.publicProfile)
  return res.data
}
