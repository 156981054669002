import { AnalysisInterface } from "@relay/interfaces"
import { apiClientWithAuth, apiEndpoints } from ".."
import { TextAnalysisResult } from "@relay/utility"
import { AnalysisType } from "../../shared/types/analysis"
import { AnalysisState } from "../../redux/reducers/analysisReducer/analysis"

export const fetchAllAnalyses = async (): Promise<AnalysisState> => {
  const res = await apiClientWithAuth.get(`${apiEndpoints.analysis}/dashboard`)
  return res.data
}

export const fetchAnalysis = async (
  type: AnalysisType,
): Promise<AnalysisInterface> => {
  const res = await apiClientWithAuth.get(`${apiEndpoints.analysis}`, {
    params: { type },
  })
  return res.data
}

export const fetchTextAnalysis = async (
  text: string,
): Promise<TextAnalysisResult> => {
  const res = await apiClientWithAuth.get(apiEndpoints.analyzeText, {
    params: { text },
  })

  return res.data
}

interface UpdateAnalysisResponse {
  _writeTime: any
}
export const updateAnalysis = async (
  analysis: Partial<AnalysisInterface>,
): Promise<UpdateAnalysisResponse> => {
  const res = await apiClientWithAuth.put(`${apiEndpoints.analysis}`, analysis)
  return res.data
}

export const removeJobFromAnalysis = async (
  jobId: string,
): Promise<AnalysisInterface> => {
  const params = { jobLeadId: jobId }
  const res = await apiClientWithAuth.get(
    `${apiEndpoints.jobs}/analysis/remove`,
    { params },
  )

  return res.data
}
