import { removeJobFromAnalysis } from "../../api-client/apiModules/analysis"
import { postToJobMarketAnalysis } from "../../api-client/apiModules/jobs"
import { AnalysisType } from "../../shared/types/analysis"
import { AnalysisState } from "../reducers/analysisReducer/analysis"

export const STORE_ANALYSIS = "STORE_ANALYSIS" as const

export interface StoreAnalysisAction {
  type: typeof STORE_ANALYSIS
  payload: Partial<AnalysisState>
}

export type AnalysisActions = StoreAnalysisAction

export const storeAnalysis = (analyses: Partial<AnalysisState>) => ({
  type: STORE_ANALYSIS,
  payload: analyses,
})

export const startAddToJobMarketAnalysis = (jobId: string) => {
  // @ts-expect-error: FIX: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    try {
      const updatedAnalysis = await postToJobMarketAnalysis(jobId)
      dispatch(storeAnalysis({ jobmarket: updatedAnalysis }))
    } catch (e) {
      console.error("Error adding to analysis.", e)
      // @ts-expect-error: FIX: Argument of type 'unknown' is not assignable to parameter of type 'string | undefined'.
      throw new Error(e)
    }
  }
}

export const startRemoveJobFromJobMarketAnalysis = (jobId: string) => {
  // @ts-expect-error: FIX: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    try {
      const updatedAnalysis = await removeJobFromAnalysis(jobId)
      dispatch(storeAnalysis({ [AnalysisType.JOBMARKET]: updatedAnalysis }))
    } catch (e) {
      console.error("Error adding to analysis.", e)
      // @ts-expect-error: FIX: Argument of type 'unknown' is not assignable to parameter of type 'string | undefined'.
      throw new Error(e)
    }
  }
}
