import { Loader } from "@googlemaps/js-api-loader"
import { environmentStrings, getFirebaseConfig } from "../../environments/utils"

const API_KEY = getFirebaseConfig(
  process.env.NODE_ENV as keyof typeof environmentStrings,
).apiKey

// @ts-expect-error: FIX: Variable 'autocompleteService' implicitly has type 'any' in some locations where its type cannot be determined.
let autocompleteService

// this is done on app start
export const loadGooglePlacesAPI = async () => {
  const loader = await new Loader({
    apiKey: API_KEY,
    version: "weekly",
    libraries: ["places"],
  })
  await loader.load()
  console.log("Google Places Api loaded.")

  const GooglePlaces = (window as any).google.maps.places
  autocompleteService = new GooglePlaces.AutocompleteService()
}

interface PlacePrediction {
  description: string // "city, state, country"
  structured_formatting: { main_text: string; secondary_text: string }
  place_id: string
}

const autocompleteQuery = async (query: string): Promise<PlacePrediction[]> => {
  // @ts-expect-error: FIX: Variable 'autocompleteService' implicitly has an 'any' type.
  const result = await autocompleteService.getPlacePredictions({
    input: query,
    types: ["(cities)"],
  })

  return result.predictions
}

export const getLocationSuggestions = async (query: string) => {
  const predictions = await autocompleteQuery(query)
  const suggestions = predictions.map((p) => p.description)
  return suggestions
}
