import React from "react"
import { useSelector } from "react-redux"
import { StoreState } from "../../redux/reducers"
import Alert from "../alert/alert"
import "./header.module.scss"
import { Navigation } from "./navigation/navigation"

/* eslint-disable-next-line */
export interface HeaderProps {}

export function Header(props: HeaderProps) {
  const site = useSelector((state: StoreState) => state.systemDefaults.site)
  return (
    <div data-testid="header">
      <Navigation />
    </div>
  )
}

export default Header
