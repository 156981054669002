import React, { useEffect } from "react"
import AdminMenu from "./AdminMenu"

// @ts-expect-error: FIX: Binding element 'mobileMenu' implicitly has an 'any' type.
const WebAdminMenu = ({ mobileMenu }) => {
  return (
    <div className="navContainer flex items-center">
      <AdminMenu mobileMenu={mobileMenu} />
    </div>
  )
}

export default WebAdminMenu
