import { ApplicationInterface } from "@relay/interfaces"
import {
  ApplicationsByJobIdActions,
  CLEAR_APPS_BY_JOB_ID,
  REMOVE_APPS_BY_JOB_ID,
  STORE_APPS_BY_JOB_ID,
} from "../actions/applicationsByJobId"

export interface ApplicationsByJobIdState {
  [jobId: string]: ApplicationInterface
}

export const initialApplicationsByJobIdState = {}

export default (
  state: ApplicationsByJobIdState = initialApplicationsByJobIdState,
  action: ApplicationsByJobIdActions,
): ApplicationsByJobIdState => {
  switch (action.type) {
    case STORE_APPS_BY_JOB_ID:
      // @ts-expect-error: FIX: Type '{ [x: string]: ApplicationInterface | undefined; }' is not assignable to type 'ApplicationsByJobIdState'. 'string' index signatures are incompatible. Type 'ApplicationInterface | undefined' is not assignable to type 'ApplicationInterface'. Type 'undefined' is not assignable to type 'ApplicationInterface'.
      return { ...state, ...action.payload }
    case CLEAR_APPS_BY_JOB_ID:
      return initialApplicationsByJobIdState
    case REMOVE_APPS_BY_JOB_ID:
      const apps = { ...state }
      delete apps[action.payload]
      return apps
    default:
      return state
  }
}
