/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useRef, useEffect } from "react"
import { Transition } from "@headlessui/react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useHistory } from "react-router-dom"
import { startSignoutUser } from "../../../redux/actions/userActions"
import { StoreState } from "../../../redux/reducers"
import logo from "../../../../assets/img/JLT-logo.svg"
import { UserRolesEnum } from "../../../redux/reducers/userReducer"
import ViewNotifications from "./notifications/viewNotifications"
import { routes } from "../../../views/router/routes"
import "./navigation.css"
import toast from "react-hot-toast"
import WebAdminMenu from "./adminMenu/WebAdminMenu"
import MobileAdminMenu from "./adminMenu/MobileAdminMenu"

export function AdminNavigation() {
  const [showUserMenu, setShowUserMenu] = useState(false)
  const [showDropDown, setShowDropDown] = useState(false)
  const container = useRef(null)
  const adminContainer = useRef(null)
  // const coachContainer = useRef(null);
  // const superAdminContainer = useRef(null);
  const [showNotifications, setShowNotifications] = useState(false)
  const [showAdminMenu, setShowAdminMenu] = useState(false)
  const [showSuperAdminMenu, setShowSuperAdminMenu] = useState(false)
  const [showCoachMenu, setShowCoachMenu] = useState(false)
  const [mobileMenu, setMobileMenu] = useState(false)
  const [headers, setHeaders] = useState([])
  const [currentHeader, setCurrentHeader] = useState("/")
  const site = useSelector((state: StoreState) => state.systemDefaults.site)
  const user = useSelector((state: StoreState) => state.user)
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const dispatch = useDispatch()

  const isUserSignedIn = !!user.uid && user.active

  useEffect(() => {
    // @ts-expect-error: FIX: 'site.header' is possibly 'undefined'.
    site.header.links.sort(function (a, b) {
      return (a?.order || 10) - (b?.order || 10)
    })
    // @ts-expect-error: FIX: Multiple errors, uncomment to see.
    setHeaders(site.header.links)
    const currentUrl = window.location.pathname
    setCurrentHeader(currentUrl)
    handleWindowSizeChange()
  }, [user, site])

  function handleWindowSizeChange() {
    if (window.innerWidth < 1024) {
      setIsSmallScreen(true)
    } else {
      setIsSmallScreen(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => window.removeEventListener("resize", handleWindowSizeChange)
  }, [])

  const history = useHistory()
  useEffect(() => {
    history.listen((location) => {
      // console.log(`You changed the page to: ${location.pathname}`);
      const currentUrl = window.location.pathname
      setCurrentHeader(currentUrl)
    })
  }, [history])

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // @ts-expect-error: FIX: Property 'contains' does not exist on type 'never'.
      if (!container?.current?.contains(event.target)) {
        if (!showUserMenu) return
        setShowUserMenu(false)
        setShowDropDown(false)
      }
    }

    window.addEventListener("click", handleOutsideClick)
    return () => window.removeEventListener("click", handleOutsideClick)
  }, [showUserMenu, container])

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // @ts-expect-error: FIX: Property 'contains' does not exist on type 'never'.
      if (!adminContainer?.current?.contains(event.target)) {
        if (!showAdminMenu) return
        setShowAdminMenu(false)
      }
    }

    window.addEventListener("click", handleOutsideClick)
    return () => window.removeEventListener("click", handleOutsideClick)
  }, [showAdminMenu, adminContainer])

  // useEffect(() => {
  //   const handleOutsideClick = (event: MouseEvent) => {
  //     if (!superAdminContainer?.current?.contains(event.target)) {
  //       if (!showSuperAdminMenu) return;
  //       setShowSuperAdminMenu(false);
  //     }
  //   };

  //   window.addEventListener('click', handleOutsideClick);
  //   return () => window.removeEventListener('click', handleOutsideClick);
  // }, [showSuperAdminMenu, superAdminContainer]);

  // useEffect(() => {
  //   const handleOutsideClick = (event: MouseEvent) => {
  //     if (!coachContainer?.current?.contains(event.target)) {
  //       if (!showCoachMenu) return;
  //       setShowCoachMenu(false);
  //     }
  //   };

  //   window.addEventListener('click', handleOutsideClick);
  //   return () => window.removeEventListener('click', handleOutsideClick);
  // }, [showCoachMenu, coachContainer]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!showUserMenu) return

      if (event.key === "Escape") {
        setShowUserMenu(false)
        setShowDropDown(false)
      }
    }

    document.addEventListener("keyup", handleEscape)
    return () => document.removeEventListener("keyup", handleEscape)
  }, [showUserMenu])

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!showNotifications) return

      if (event.key === "Escape") {
        setShowNotifications(false)
      }
    }

    document.addEventListener("keyup", handleEscape)
    return () => document.removeEventListener("keyup", handleEscape)
  }, [showNotifications])

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!showAdminMenu) return

      if (event.key === "Escape") {
        setShowAdminMenu(false)
      }
    }

    document.addEventListener("keyup", handleEscape)
    return () => document.removeEventListener("keyup", handleEscape)
  }, [showAdminMenu])

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!showSuperAdminMenu) return

      if (event.key === "Escape") {
        setShowSuperAdminMenu(false)
      }
    }

    document.addEventListener("keyup", handleEscape)
    return () => document.removeEventListener("keyup", handleEscape)
  }, [showSuperAdminMenu])

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!showCoachMenu) return

      if (event.key === "Escape") {
        setShowCoachMenu(false)
      }
    }

    document.addEventListener("keyup", handleEscape)
    return () => document.removeEventListener("keyup", handleEscape)
  }, [showCoachMenu])

  const onSignoutClick = async () => {
    try {
      await dispatch(startSignoutUser(user))
      setShowUserMenu(!showUserMenu)
      setShowDropDown(!showDropDown)
    } catch (e) {
      console.error("Error signing out. ", e)
      toast.error("Error signing out.")
    }
  }

  // @ts-expect-error: FIX: Multiple errors, uncomment to see.
  const navClick = (id, mobile) => {
    if (mobile) {
      // @ts-expect-error: FIX: Object is possibly 'undefined'.
      setCurrentHeader(mobileMenuHeaders[id].interUrl)
    } else {
      // @ts-expect-error: FIX: Object is possibly 'undefined'.
      setCurrentHeader(headers[id].interUrl)
    }
    if (mobileMenu) {
      setMobileMenu(!mobileMenu)
    }
  }

  // @ts-expect-error: FIX: Parameter 'path' implicitly has an 'any' type.
  const onMenuItemClick = (path) => {
    setShowCoachMenu(false)
    setShowAdminMenu(false)
    setShowUserMenu(false)
    setShowDropDown(false)
    setShowSuperAdminMenu(false)
    setCurrentHeader(path)
  }

  const defaultUserPhotoURL =
    "https://www.gravatar.com/avatar/00000000000000000000000000000000"
  const userPhotoURL = user.photoURL || defaultUserPhotoURL

  const mobileMenuHeaders = [
    { name: "Your Account", interUrl: routes.profile },
    {
      name: "Sign Out",
      interUrl: routes.login,
      onClick: () => onSignoutClick(),
    },
  ]

  // @ts-expect-error: FIX: Rest parameter 'classes' implicitly has an 'any[]' type.
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }
  let courseTracking = {} as any
  if (user && user.course && user.courseInfo) {
    courseTracking = user.courseInfo.courseTracking
  }

  const userHasAccessToAdminMenu =
    user.roles.includes(UserRolesEnum.ADMIN) ||
    user.roles.includes(UserRolesEnum.GROUPADMIN) ||
    user.roles.includes(UserRolesEnum.COHORTADMIN) ||
    user.roles.includes(UserRolesEnum.JOBADMIN) ||
    user.roles.includes(UserRolesEnum.COURSEADMIN) ||
    user.roles.includes(UserRolesEnum.SUPERADMIN) ||
    user.roles.includes(UserRolesEnum.SUCCESSCOACH) || // this is added for now so that SMs,TAs,PDs can get the user management page
    user.roles.includes(UserRolesEnum.CAREERCOACH) ||
    user.roles.includes(UserRolesEnum.TEACHINGASSISTANT) ||
    user.roles.includes(UserRolesEnum.PROGRAMINSTRUCTOR) ||
    user.roles.includes(UserRolesEnum.PROGRAMDIRECTOR) ||
    user.roles.includes(UserRolesEnum.COACH)

  // const userHasAccessToSuperAdminMenu = user.roles.includes(
  //   UserRolesEnum.SUPERADMIN
  // );

  // turned of for now because we arent using any pages in this dropdown, if we need them back -> uncomment this out
  // const userHasAccessToCoachMenu = false;
  // props.user.roles.includes(UserRolesEnum.COURSEADMIN) ||
  // props.user.roles.includes(UserRolesEnum.COACH) ||
  // props.user.roles.includes(UserRolesEnum.JOBADMIN) ||
  // props.user.roles.includes(UserRolesEnum.SUCCESSCOACH) ||
  // props.user.roles.includes(UserRolesEnum.RETENTIONCOACH) ||
  // props.user.roles.includes(UserRolesEnum.CAREERCOACH) ||
  // props.user.roles.includes(UserRolesEnum.DIRECTOROFOUTCOMES) ||
  // props.user.roles.includes(UserRolesEnum.PROGRAMDIRECTOR) ||
  // props.user.roles.includes(UserRolesEnum.PROGRAMINSTRUCTOR) ||
  // props.user.roles.includes(UserRolesEnum.TEACHINGASSISTANT) ||
  // props.user.roles.includes(UserRolesEnum.SUCCESSCOACHMANAGER) ||
  // props.user.roles.includes(UserRolesEnum.CAREERCOACHMANAGER) ||
  // props.user.roles.includes(UserRolesEnum.ADMIN) ||
  // props.user.roles.includes(UserRolesEnum.SUPERADMIN);

  // @ts-expect-error: FIX: 'site.images' is possibly 'undefined'.
  let siteLogo: any = site.images.logo || logo

  if (window.location.pathname === routes.login) {
    siteLogo = false
  }

  const adminMobileHeaders = [{ name: "Home", interUrl: "/" }]

  return (
    <>
      {user.email && (
        <nav
          // @ts-expect-error: FIX: Multiple errors, uncomment to see.
          className={user.email ? site.colors.background.navbar : "bg-gray-800"}
        >
          <div className="max-w-8xl mx-auto border-b px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex px-2 lg:px-0">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-9 w-auto lg:block"
                    src={siteLogo}
                    alt="Logo"
                  ></img>
                </div>
                <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                  <div className="relative z-50 flex flex-shrink-0">
                    <div className="flex items-center">
                      {
                        userHasAccessToAdminMenu && user.onboarding.initial ? (
                          <WebAdminMenu mobileMenu={isSmallScreen} />
                        ) : null
                        // isUserSignedIn && (
                        //   <div className="hidden sm:block sm:ml-6">
                        //     <div className="flex space-x-4">
                        //       {user.onboarding.initial
                        //         ? headers.map((item, idx) =>
                        //             item.name === 'Course' ? (
                        //               <></>
                        //             ) : (item.name === 'Jobs' ||
                        //                 item.name === 'Applications' ||
                        //                 item.name === 'Analysis') &&
                        //               !user.roles.includes('Job Hunter') ? (
                        //               <></>
                        //             ) : !item.role ||
                        //               user.roles.includes(item.role) ? (
                        //               item.type == 'dropdown' ? (
                        //                 <a
                        //                   onClick={() =>
                        //                     setShowDropDown(!showDropDown)
                        //                   }
                        //                   className={`${site.colors.text.navbar} ${site.colors.hover.navbar_background} ${site.colors.hover.navbar_border} ${site.colors.hover.navbar_text} px-3 py-2 rounded-md font-medium`}
                        //                   id="user-menu"
                        //                   aria-haspopup="true"
                        //                 >
                        //                   {item.name}

                        //                   <Transition
                        //                     show={showDropDown}
                        //                     enter="transition ease-out duration-100 transform"
                        //                     enterFrom="opacity-0 scale-95"
                        //                     enterTo="opacity-100 scale-100"
                        //                     leave="transition ease-in duration-75 transform"
                        //                     leaveFrom="opacity-100 scale-100"
                        //                     leaveTo="opacity-0 scale-95"
                        //                   >
                        //                     <div
                        //                       className="origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                        //                       role="menu"
                        //                       aria-orientation="vertical"
                        //                       aria-labelledby="user-menu"
                        //                     >
                        //                       {item.links.map(
                        //                         (dropdownlink, dpId) => (
                        //                           <a
                        //                             className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        //                             role="menuitem"
                        //                             target="_blank"
                        //                             href={dropdownlink.url}
                        //                           >
                        //                             {dropdownlink.name}
                        //                             {courseTracking.modules[
                        //                               dropdownlink.moduleId
                        //                             ]?.completed ? (
                        //                               <CheckCircleIcon
                        //                                 height={20}
                        //                                 width={20}
                        //                                 className="float-left"
                        //                                 color={
                        //                                   TAILWIND_COLORS.green400
                        //                                 }
                        //                               />
                        //                             ) : (
                        //                               <PlayIcon
                        //                                 height={20}
                        //                                 width={20}
                        //                                 className="float-left"
                        //                                 color={
                        //                                   TAILWIND_COLORS.yellow600
                        //                                 }
                        //                               />
                        //                             )}
                        //                           </a>
                        //                         )
                        //                       )}
                        //                     </div>
                        //                   </Transition>
                        //                 </a>
                        //               ) : item.interUrl ? (
                        //                 <NavLink
                        //                   key={`nav-link-non-mobile-${idx}`}
                        //                   exact
                        //                   to={item.interUrl}
                        //                   onClick={() => navClick(idx, false)}
                        //                   className={classNames(
                        //                     item.interUrl === currentHeader
                        //                       ? `${site.colors.active.navbar_background} ${site.colors.active.navbar_text}` //
                        //                       : `${site.colors.text.navbar} ${site.colors.hover.navbar_background} ${site.colors.hover.navbar_border} ${site.colors.hover.navbar_text}`, //text-gray-300 hover:bg-gray-700 hover:text-white
                        //                     'px-3 py-2 rounded-md font-medium'
                        //                   )}
                        //                   aria-current={
                        //                     item.interUrl === currentHeader
                        //                       ? 'page'
                        //                       : undefined
                        //                   }
                        //                   role="menuitem"
                        //                 >
                        //                   {item.name}
                        //                 </NavLink>
                        //               ) : (
                        //                 <a
                        //                   href={item.url}
                        //                   target="_blank"
                        //                   className={`${site.colors.text.navbar} ${site.colors.hover.navbar_background} ${site.colors.hover.navbar_border} ${site.colors.hover.navbar_text} px-3 py-2 rounded-md font-medium`}
                        //                   aria-current={
                        //                     item.interUrl === currentHeader
                        //                       ? 'page'
                        //                       : undefined
                        //                   }
                        //                 >
                        //                   {item.name}
                        //                 </a>
                        //               )
                        //             ) : null
                        //           )
                        //         : null}
                        //     </div>
                        //   </div>
                        // )
                      }
                      {/* {!isUserSignedIn && (
                        <div className="hidden sm:block sm:ml-2">
                          <div className="flex space-x-4">
                            <NavLink
                          key={`nav-link-non-mobile-pricing`}
                          exact
                          to="/beta"
                          className={classNames(
                            '/beta' === currentHeader
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'px-3 py-2 rounded-md text-sm font-medium'
                          )}
                          aria-current={
                            '/beta' === currentHeader ? 'page' : undefined
                          }
                          role="menuitem"
                        >
                          Beta
                        </NavLink>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center lg:hidden">
                <button
                  type="button"
                  className="icon-tertiary icon-md mr-0 shadow-none"
                  style={{ backgroundColor: "white" }}
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                  onClick={() => setMobileMenu(!mobileMenu)}
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="black"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  <svg
                    className="hidden h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              {isUserSignedIn && (
                <div className="hidden lg:flex lg:items-center">
                  {/* {userHasAccessToCoachMenu && (
                    <CoachMenu
                      container={coachContainer}
                      onMenuItemClick={onMenuItemClick}
                      setShowCoachMenu={setShowCoachMenu}
                      showCoachMenu={showCoachMenu}
                    />
                  )}

                  {userHasAccessToSuperAdminMenu && (
                    <SuperAdminMenu
                      container={superAdminContainer}
                      onMenuItemClick={onMenuItemClick}
                      setShowSuperAdminMenu={setShowSuperAdminMenu}
                      showSuperAdminMenu={showSuperAdminMenu}
                    />
                  )} */}

                  {user.onboarding.initial ? (
                    <ViewNotifications
                      showNotifications={showNotifications}
                      setShowNotificationsList={setShowNotifications}
                    />
                  ) : null}
                  <div className="relative z-50 flex-shrink-0" ref={container}>
                    <div>
                      <button
                        onClick={() => setShowUserMenu(!showUserMenu)}
                        className="icon-secondary border-none shadow-none"
                        style={{ backgroundColor: "#FFF", border: "none" }}
                        id="user-menu"
                        aria-haspopup="true"
                      >
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-7 w-7 rounded-full"
                          src={userPhotoURL}
                          referrerPolicy="no-referrer"
                          alt=""
                        ></img>
                      </button>
                    </div>
                    <Transition
                      show={showUserMenu}
                      enter="transition ease-out duration-100 transform"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="transition ease-in duration-75 transform"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <div
                        className="absolute right-0 mt-2 w-60 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu"
                      >
                        {/* <NavLink
                      onClick={() => userMenuClicks(false, '/profile')}
                      exact
                      to="/profile"
                      activeClassName="bg-gray-100"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      Your Profile
                    </NavLink> */}

                        {user.onboarding.initial ? (
                          <NavLink
                            onClick={() => onMenuItemClick(routes.profile)}
                            exact
                            to={routes.profile}
                            activeClassName="bg-gray-100"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            Your Account
                          </NavLink>
                        ) : null}
                        {/* <NavLink
                      onClick={() => onMenuItemClick('/preferences')}
                      exact
                      to="/preferences"
                      activeClassName="bg-gray-100"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Preferences
                    </NavLink>
                    <NavLink
                      onClick={() => onMenuItemClick('/settings')}
                      exact
                      to="/settings"
                      activeClassName="bg-gray-100"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Settings
                    </NavLink> */}

                        {/* <NavLink
                      onClick={() =>
                        userMenuClicks(false, '/application-analysis')
                      }
                      exact
                      to="/application-analysis"
                      activeClassName="bg-gray-100"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Application Analysis
                    </NavLink> */}
                        {/* <NavLink
                          onClick={onSignoutClick}
                          exact
                          to="/login"
                          activeClassName="bg-gray-100"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                        >
                          Sign out
                        </NavLink> */}
                        <button
                          onClick={onSignoutClick}
                          className="m-0 block w-full px-4 py-2 text-left text-sm text-gray-700 shadow-none"
                          role="menuitem"
                          id="sign-out-btn"
                        >
                          Sign out
                        </button>
                      </div>
                    </Transition>
                  </div>
                </div>
              )}
            </div>
          </div>

          {isUserSignedIn && userHasAccessToAdminMenu && mobileMenu && (
            <div
              className="flex flex-col rounded-lg bg-white lg:hidden"
              id="mobile-menu"
            >
              <div className="p-4">
                <MobileAdminMenu mobileMenu={isSmallScreen} />
              </div>

              <div className="border-t border-gray-200 pb-3 pt-4">
                {user.onboarding.initial ? (
                  <ViewNotifications
                    showNotifications={showNotifications}
                    setShowNotificationsList={setShowNotifications}
                    isMobile={true}
                  />
                ) : null}
                <div className="mt-2 space-y-1">
                  {mobileMenuHeaders.map((item, idx) => (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                      {user.onboarding.initial ? (
                        <NavLink
                          key={`nav-link-mobile-${idx}`}
                          exact
                          to={item.interUrl}
                          onClick={
                            item.onClick
                              ? item.onClick
                              : () => navClick(idx, true)
                          }
                          className={classNames(
                            item.interUrl === currentHeader
                              ? // @ts-expect-error: FIX: Multiple errors, uncomment to see.
                                `${site.colors.active.navbar_background} ${site.colors.active.navbar_border} ${site.colors.active.navbar_text}`
                              : // @ts-expect-error: FIX: Multiple errors, uncomment to see.
                                `border-transparent ${site.colors.text.navbar} ${site.colors.hover.navbar_background} ${site.colors.hover.navbar_border} ${site.colors.hover.navbar_text}`,
                            "block border-l-4 py-2 pl-3 pr-4 text-base font-medium",
                          )}
                          aria-current={
                            item.interUrl === currentHeader ? "page" : undefined
                          }
                          role="menuitem"
                        >
                          {item.name}
                        </NavLink>
                      ) : item.name === "Sign Out" ? (
                        <NavLink
                          key={`nav-link-mobile-${idx}`}
                          exact
                          to={item.interUrl}
                          onClick={
                            item.onClick
                              ? item.onClick
                              : () => navClick(idx, true)
                          }
                          className={classNames(
                            item.interUrl === currentHeader
                              ? // @ts-expect-error: FIX: Multiple errors, uncomment to see.
                                `${site.colors.active.navbar_background} ${site.colors.active.navbar_border} ${site.colors.active.navbar_text}`
                              : // @ts-expect-error: FIX: Multiple errors, uncomment to see.
                                `border-transparent ${site.colors.text.navbar} ${site.colors.hover.navbar_background} ${site.colors.hover.navbar_border} ${site.colors.hover.navbar_text}`,
                            "block border-l-4 py-2 pl-3 pr-4 text-base font-medium",
                          )}
                          aria-current={
                            item.interUrl === currentHeader ? "page" : undefined
                          }
                          role="menuitem"
                        >
                          {item.name}
                        </NavLink>
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            </div>
          )}
        </nav>
      )}
    </>
  )
}

export default AdminNavigation
