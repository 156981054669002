import { SystemDefaultsState } from "../reducers/systemDefaults"

export const STORE_SYSTEM_DEFAULTS = "STORE_SYSTEM_DEFAULTS" as const

export interface SystemDefaultsAction {
  type: typeof STORE_SYSTEM_DEFAULTS
  payload: Partial<SystemDefaultsState>
}

export type SystemDefaultsActions = SystemDefaultsAction

export const storeSystemDefaults = (
  systemDefaults: Partial<SystemDefaultsState>,
) => ({
  type: STORE_SYSTEM_DEFAULTS,
  payload: systemDefaults,
})
