import { PreferencesInterface } from "@relay/interfaces"
import { PreferencesActions, STORE_PREFERENCES } from "../actions/preferences"

export type PreferencesState = PreferencesInterface

export const initialPreferencesState: PreferencesState = {
  locations: [], // ex "Los Angeles, CA, United States"
  skills: [],
  jobSites: [],
  seniorityLevels: [],
  remote: true,
  preferredSearch: { searchId: "", name: "" },
}

export default (
  state: PreferencesState = initialPreferencesState,
  action: PreferencesActions,
): PreferencesState => {
  switch (action.type) {
    case STORE_PREFERENCES:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
