import React from "react"
import { Transition } from "@headlessui/react"
import { NotificationInterface } from "@relay/interfaces"
import NotificationTile from "../notification-tile"
import Loading from "../../../loading/loading"

interface NotificationsListProps {
  show: boolean
  notifications: NotificationInterface[]
  loading: boolean
  removeNotification: (id: string) => void
  isMobile: boolean
}

// @ts-expect-error: FIX: Multiple errors, uncomment to see.
const List = ({ notifications, removeNotification }) => {
  if (notifications.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <p style={{ fontSize: "16px" }}>No</p>
        <p style={{ fontSize: "16px" }}>Notifications</p>
      </div>
    )
  }

  return notifications.map((n: NotificationInterface) => {
    return (
      <>
        {console.log(n)}
        <NotificationTile
          notification={n}
          key={n.id}
          removeNotification={removeNotification}
        />
      </>
    )
  })
}

const NotificationsList: React.FunctionComponent<NotificationsListProps> = ({
  show,
  notifications,
  loading,
  removeNotification,
  isMobile = false,
}) => {
  return (
    <Transition
      show={show}
      enter="transition ease-out duration-100 transform"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition ease-in duration-75 transform"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div
        className={
          isMobile
            ? "absolute right-5 z-10 mt-2 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
            : "absolute right-0 mt-2 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        }
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu"
      >
        <div className="border-b">
          <h3 className="m-0 w-40 p-2 text-left font-medium text-gray-600">
            Notifications
          </h3>
        </div>

        {loading ? (
          <Loading />
        ) : (
          <List
            notifications={notifications}
            removeNotification={removeNotification}
          />
        )}
      </div>
    </Transition>
  )
}

export default NotificationsList
