/**
 * Trying to import this from @relay/models caused weird errors, duplicating here.
 * Make sure to keep this up to date if changes are made on the original enum
 */

export enum AnalysisType {
  INTEREST = "interest",
  APPLICATIONS = "applications",
  JOBMARKET = "jobmarket",
  RESUME = "resume",
  TASK = "task",
  INTERVIEW = "interview",
  HISTORY = "history",
}
