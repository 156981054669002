import { postJobApplyClickEventToAlgolia } from "../../api-client/apiModules/algolia"
import { fetchAnalysis } from "../../api-client/apiModules/analysis"
import {
  createApplicationByJobId,
  deleteApplicationByJobId,
} from "../../api-client/apiModules/applications"
import { AnalysisType } from "../../shared/types/analysis"
import { ApplicationType } from "../../shared/types/applications"
import { ApplicationsByJobIdState } from "../reducers/applicationsByJobId"
import { storeAnalysis } from "./analysis"

export const STORE_APPS_BY_JOB_ID = "STORE_APPS_BY_JOB_ID" as const
export const CLEAR_APPS_BY_JOB_ID = "CLEAR_APPS_BY_JOB_ID" as const
export const REMOVE_APPS_BY_JOB_ID = "REMOVE_APPS_BY_JOB_ID" as const

export interface StoreAppsByJobIdAction {
  type: typeof STORE_APPS_BY_JOB_ID
  payload: Partial<ApplicationsByJobIdState>
}
export interface ClearAppsByJobIdAction {
  type: typeof CLEAR_APPS_BY_JOB_ID
}
export interface RemoveAppsByJobIdAction {
  type: typeof REMOVE_APPS_BY_JOB_ID
  payload: string
}

export type ApplicationsByJobIdActions =
  | StoreAppsByJobIdAction
  | ClearAppsByJobIdAction
  | RemoveAppsByJobIdAction

export const storeAppsByJobId = (
  appsByJobId: Partial<ApplicationsByJobIdState>,
) => ({
  type: STORE_APPS_BY_JOB_ID,
  payload: appsByJobId,
})

export const removeAppsByJobId = (appsByJobId: string) => ({
  type: REMOVE_APPS_BY_JOB_ID,
  payload: appsByJobId,
})

export const clearAppsByJobId = () => ({
  type: CLEAR_APPS_BY_JOB_ID,
})

export const startCreateAppByJobId = (
  jobId: string,
  appType: ApplicationType,
) => {
  // @ts-expect-error: FIX: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    try {
      const postApplicationRes = await createApplicationByJobId(jobId, appType)
      await postJobApplyClickEventToAlgolia(jobId)

      const updatedAppAnalysis = await fetchAnalysis(AnalysisType.APPLICATIONS)
      const updatedJobMarketAnalysis = await fetchAnalysis(
        AnalysisType.JOBMARKET,
      )

      dispatch(
        storeAnalysis({
          [AnalysisType.APPLICATIONS]: {
            ...updatedAppAnalysis,
          },
        }),
      )
      dispatch(
        storeAnalysis({
          [AnalysisType.JOBMARKET]: {
            ...updatedJobMarketAnalysis,
          },
        }),
      )
      dispatch(
        storeAppsByJobId({
          [jobId]: postApplicationRes.application,
        }),
      )
    } catch (e) {
      console.error("Error creating app by job id", e)
      // @ts-expect-error: FIX: Argument of type 'unknown' is not assignable to parameter of type 'string | undefined'.
      throw new Error(e)
    }
  }
}

export const startDeleteAppByJobId = (jobId: string) => {
  // @ts-expect-error: FIX: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    try {
      await deleteApplicationByJobId(jobId)

      const updatedAppAnalysis = await fetchAnalysis(AnalysisType.APPLICATIONS)
      const updatedJobMarketAnalysis = await fetchAnalysis(
        AnalysisType.JOBMARKET,
      )

      dispatch(
        storeAnalysis({
          [AnalysisType.APPLICATIONS]: {
            ...updatedAppAnalysis,
          },
        }),
      )
      dispatch(
        storeAnalysis({
          [AnalysisType.JOBMARKET]: {
            ...updatedJobMarketAnalysis,
          },
        }),
      )
      dispatch(removeAppsByJobId(jobId))
    } catch (e) {
      console.error("Error creating app by job id", e)
      // @ts-expect-error: FIX: Argument of type 'unknown' is not assignable to parameter of type 'string | undefined'.
      throw new Error(e)
    }
  }
}
