import React, { useEffect } from "react"
import { BrowserRouter } from "react-router-dom"
import Footer from "./components/footer/footer"
import Header from "./components/header/header"
import Notification from "./components/notification/notification"
import Router from "./views/router/router"
import Alert from "./components/alert/alert"
import toast, { Toaster } from "react-hot-toast"
import {
  isInitialOnboardingComplete,
  onboardingAlertMessage,
} from "./util/onboarding"
import { useSelector } from "react-redux"
import { StoreState } from "./redux/reducers"
import "./app.module.scss"
import * as Sentry from "@sentry/react"

export function App() {
  const user = useSelector((state: StoreState) => state.user)

  return (
    <div data-testid="app" className="background-styles flex h-screen flex-col">
      <div style={{ flex: "1 1 0" }}>
        <Header></Header>
        {user.uid &&
          !isInitialOnboardingComplete(user.onboarding) &&
          user.active}
        {/* && <Alert message={onboardingAlertMessage}></Alert>} */}
        <Notification></Notification>
        <Router></Router>
        <Toaster position="top-center" reverseOrder={false} gutter={8} />
      </div>
      {/* <Footer></Footer> */}
    </div>
  )
}

export default Sentry.withProfiler(App)
