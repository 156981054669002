import {
  IPAD_SCREEN_WIDTH,
  PHONE_SCREEN_WIDTH,
  LARGE_SCREEN_WIDTH,
} from "../styles/dimensions"
import useDimensions from "./useDimensions"

export const useBreakpoints = () => {
  const { width } = useDimensions()

  const isSmallScreen = width <= LARGE_SCREEN_WIDTH

  const isMobile = width <= IPAD_SCREEN_WIDTH
  const isTablet = width <= LARGE_SCREEN_WIDTH && !isMobile
  const isDesktop = !isTablet && !isMobile

  return {
    isMobile,
    isTablet,
    isDesktop,
    isSmallScreen,
  }
}
