import { TriggersInterface } from "@relay/interfaces"
import {
  CLEAR_TRIGGERS,
  STORE_TRIGGERS,
  TriggersActions,
} from "../actions/triggers"

export type TriggersState = TriggersInterface

export const initialTriggersState: TriggersState = {}

export default (
  state: TriggersState = initialTriggersState,
  action: TriggersActions,
): TriggersState => {
  switch (action.type) {
    case STORE_TRIGGERS:
      return { ...state, ...action.triggers }
    case CLEAR_TRIGGERS:
      return initialTriggersState
    default:
      return state
  }
}
