import React, { useEffect } from "react"
import { Menu, Transition } from "@headlessui/react"
import { NavLink, useHistory } from "react-router-dom"
import { routes } from "../../../../views/router/routes"
import "./adminMenu.css"
import { Fragment, useState } from "react"
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid"

// @ts-expect-error: FIX: Rest parameter 'classes' implicitly has an 'any[]' type.
function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

// @ts-expect-error: FIX: Binding element 'mobileMenu' implicitly has an 'any' type.
const AdminMenu = ({ mobileMenu }) => {
  const checkUrl = () => {
    const currentUrl = window.location.pathname
    switch (currentUrl) {
      case "/":
        return "Home"
      case "/admin/onboarding":
        return "Progress"
      case "/admin/learning":
        return "Progress"
      case "/admin/job-search":
        return "Progress"
      case "/errors":
        return "Legacy"
      case routes.reportedJobs:
        return "Legacy"
      case routes.customTriggers:
        return "Legacy"
      case routes.userSystemTracking:
        return "Legacy"
      case routes.userManagement:
        return "Users"
    }
  }

  // @ts-expect-error: FIX: Argument of type 'string | undefined' is not assignable to parameter of type 'string | (() => string)'. Type 'undefined' is not assignable to type 'string | (() => string)'.
  const [currentHeader, setCurrentHeader] = useState<string>(checkUrl())
  const [progressMenuOpen, setProgressMenuOpen] = useState(false)
  const [progressMenuHover, setProgressMenuHover] = useState(false)
  const [legacyMenuOpen, setlegacyMenuOpen] = useState(false)
  const [legacyMenuHover, setLegacyMenuHover] = useState(false)
  const [selectedDropdownItem, setSelectedDropdownItem] = useState("")

  const menuItems = {
    Onboarding: "Onboarding",
    Learning: "Learning",
    JobSearch: "Job Search",
    Errors: "Errors",
    ReportedJobs: "Reported Jobs",
    CustomTriggers: "Custom Triggers",
    UsersTracking: "Users Tracking",
  }

  // @ts-expect-error: FIX: Parameter 'pathName' implicitly has an 'any' type.
  const onMenuItemClick = (pathName, selected?: string) => {
    setCurrentHeader(pathName)
    // @ts-expect-error: FIX: Argument of type 'string | undefined' is not assignable to parameter of type 'SetStateAction<string>'.
    setSelectedDropdownItem(selected)
  }

  useEffect(() => {
    if (progressMenuOpen === false && legacyMenuOpen === false) {
      // @ts-expect-error: FIX: Argument of type 'string | undefined' is not assignable to parameter of type 'SetStateAction<string>'. Type 'undefined' is not assignable to type 'SetStateAction<string>'.
      setCurrentHeader(checkUrl())
    }
  }, [progressMenuOpen, legacyMenuOpen])

  const screenSizeChange = async () => {
    const page = checkUrl()
    // @ts-expect-error: FIX: Argument of type 'string | undefined' is not assignable to parameter of type 'SetStateAction<string>'.
    setCurrentHeader(page)
  }

  useEffect(() => {
    window.addEventListener("resize", screenSizeChange)
    return () => {
      window.removeEventListener("resize", screenSizeChange)
    }
  }, [])

  const history = useHistory()
  useEffect(() => {
    history.listen((location) => {
      const current = checkUrl()
      // @ts-expect-error: FIX: Argument of type 'string | undefined' is not assignable to parameter of type 'SetStateAction<string>'.
      setCurrentHeader(current)
    })
  }, [history])

  return (
    <>
      <NavLink
        to="/"
        onClick={() => onMenuItemClick("Home")}
        className={` ${
          currentHeader === "Home"
            ? "adminButton-clicked flex items-center rounded-lg bg-black pb-0 pl-3 pr-3 pt-0 leading-8 text-white"
            : "adminButton flex items-center rounded-lg pb-0 pl-3 pr-3 pt-0 leading-8 text-black"
        } ${mobileMenu ? "w-1/2 justify-start" : "justify-start"} `}
      >
        Home
      </NavLink>

      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => {
          setProgressMenuOpen(open)
          if (open) {
            setCurrentHeader("Progress")
          }
          return (
            <>
              <div>
                <Menu.Button
                  style={{
                    backgroundColor: `${
                      currentHeader === "Progress"
                        ? "rgb(0 0 0)"
                        : progressMenuHover
                          ? "rgb(243 244 246)"
                          : "#FFF"
                    }`,
                    color: `${
                      currentHeader === "Progress" ? "#FFF" : "rgb(0 0 0)"
                    }`,
                    margin: 0,
                  }}
                  className={` ${
                    currentHeader === "Progress"
                      ? "adminButton-clicked flex items-center rounded-lg bg-black pb-0 pl-3 pr-3 pt-0 leading-8 text-white"
                      : "adminButton flex items-center rounded-lg pb-0 pl-3 pr-3 pt-0 leading-8 text-black"
                  } ${
                    mobileMenu
                      ? "w-1/2 justify-start justify-between"
                      : "justify-start"
                  } `}
                  id="dropdown-menu"
                  onMouseEnter={() => setProgressMenuHover(true)}
                  onMouseLeave={() => setProgressMenuHover(false)}
                >
                  Progress
                  {mobileMenu ? (
                    <ChevronRightIcon
                      className={`-mr-1 h-6 w-6 ${
                        currentHeader === "Progress"
                          ? "text-white"
                          : "text-black"
                      }`}
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronDownIcon
                      className={`-mr-1 h-6 w-6 ${
                        currentHeader === "Progress"
                          ? "text-white"
                          : "text-black"
                      }`}
                      aria-hidden="true"
                    />
                  )}
                </Menu.Button>
              </div>
              {open && (
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    style={{
                      border: "1px solid #8D89A7",
                      left: `${mobileMenu ? "51%" : ""}`,
                    }}
                    className={
                      mobileMenu
                        ? `absolute top-0 z-10 w-56 origin-top-right rounded-lg bg-white focus:outline-none`
                        : `absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-lg bg-white focus:outline-none`
                    }
                  >
                    <div className="menu-styles">
                      <Menu.Item>
                        {({ active }) => {
                          return (
                            <NavLink
                              onClick={() =>
                                onMenuItemClick(
                                  "Progress",
                                  menuItems.Onboarding,
                                )
                              }
                              exact
                              to={routes.adminOnboardingDashboard}
                              className={classNames(
                                active ? "selected-link w-full" : "",
                                "flex flex-col items-start px-4 py-2",
                                selectedDropdownItem === menuItems.Onboarding
                                  ? "selected-menuItem w-full"
                                  : "",
                              )}
                              role="menuitem"
                            >
                              Onboarding
                            </NavLink>
                          )
                        }}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <NavLink
                            onClick={() =>
                              onMenuItemClick("Progress", menuItems.Learning)
                            }
                            exact
                            to="/admin/learning"
                            className={classNames(
                              active ? "selected-link w-full" : "",
                              "flex flex-col items-start px-4 py-2",
                              selectedDropdownItem === menuItems.Learning
                                ? "selected-menuItem w-full"
                                : "",
                            )}
                            role="menuitem"
                          >
                            Learning
                          </NavLink>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <NavLink
                            onClick={() =>
                              onMenuItemClick("Progress", menuItems.JobSearch)
                            }
                            exact
                            to="/admin/job-search"
                            className={classNames(
                              active ? "selected-link w-full" : "",
                              "flex flex-col items-start px-4 py-2",
                              selectedDropdownItem === menuItems.JobSearch
                                ? "selected-menuItem w-full"
                                : "",
                            )}
                            role="menuitem"
                          >
                            Job Search
                          </NavLink>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              )}
            </>
          )
        }}
      </Menu>

      {/* LEGACY PAGES */}
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => {
          setlegacyMenuOpen(open)
          if (open) {
            setCurrentHeader("Legacy")
          }
          return (
            <>
              <div>
                <Menu.Button
                  style={{
                    backgroundColor: `${
                      currentHeader === "Legacy"
                        ? "rgb(0 0 0)"
                        : legacyMenuHover
                          ? "rgb(243 244 246)"
                          : "#FFF"
                    }`,
                    color: `${
                      currentHeader === "Legacy" ? "#FFF" : "rgb(0 0 0)"
                    }`,
                    margin: 0,
                  }}
                  className={` ${
                    currentHeader === "Legacy"
                      ? "adminButton-clicked flex items-center rounded-lg bg-black pb-0 pl-3 pr-3 pt-0 leading-8 text-white"
                      : "adminButton flex items-center rounded-lg pb-0 pl-3 pr-3 pt-0 leading-8 text-black"
                  } ${
                    mobileMenu
                      ? "w-1/2 justify-start justify-between"
                      : "justify-start"
                  } `}
                  id="dropdown-menu"
                  onMouseEnter={() => setLegacyMenuHover(true)}
                  onMouseLeave={() => setLegacyMenuHover(false)}
                >
                  Legacy Pages
                  {mobileMenu ? (
                    <ChevronRightIcon
                      className={`-mr-1 h-6 w-6 ${
                        currentHeader === "Legacy" ? "text-white" : "text-black"
                      }`}
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronDownIcon
                      className={`-mr-1 h-6 w-6 ${
                        currentHeader === "Legacy" ? "text-white" : "text-black"
                      }`}
                      aria-hidden="true"
                    />
                  )}
                </Menu.Button>
              </div>
              {open && (
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    style={{
                      border: "1px solid #8D89A7",
                      left: `${mobileMenu ? "51%" : ""}`,
                    }}
                    className={
                      mobileMenu
                        ? `absolute top-0 z-10 w-56 origin-top-right rounded-lg bg-white focus:outline-none`
                        : `absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-lg bg-white focus:outline-none`
                    }
                  >
                    <div className="menu-styles">
                      <Menu.Item>
                        {({ active }) => (
                          <NavLink
                            onClick={() =>
                              onMenuItemClick("Legacy", menuItems.Errors)
                            }
                            exact
                            to="/errors"
                            className={classNames(
                              active ? "selected-link w-full" : "",
                              "flex flex-col items-start px-4 py-2",
                              selectedDropdownItem === menuItems.Errors
                                ? "selected-menuItem w-full"
                                : "",
                            )}
                            role="menuitem"
                          >
                            Errors
                          </NavLink>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <NavLink
                            onClick={() =>
                              onMenuItemClick("Legacy", menuItems.ReportedJobs)
                            }
                            exact
                            to={routes.reportedJobs}
                            className={classNames(
                              active ? "selected-link w-full" : "",
                              "flex flex-col items-start px-4 py-2",
                              selectedDropdownItem === menuItems.ReportedJobs
                                ? "selected-menuItem w-full"
                                : "",
                              selectedDropdownItem === menuItems.ReportedJobs
                                ? "selected-menuItem w-full"
                                : "",
                            )}
                            role="menuitem"
                          >
                            Review Reported Jobs
                          </NavLink>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <NavLink
                            onClick={() =>
                              onMenuItemClick(
                                "Legacy",
                                menuItems.CustomTriggers,
                              )
                            }
                            exact
                            to={routes.customTriggers}
                            className={classNames(
                              active ? "selected-link w-full" : "",
                              "flex flex-col items-start px-4 py-2",
                              selectedDropdownItem === menuItems.CustomTriggers
                                ? "selected-menuItem w-full"
                                : "",
                            )}
                            role="menuitem"
                          >
                            Create Custom Triggers
                          </NavLink>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <NavLink
                            onClick={() =>
                              onMenuItemClick("Legacy", menuItems.UsersTracking)
                            }
                            exact
                            to={routes.userSystemTracking}
                            className={classNames(
                              active ? "selected-link w-full" : "",
                              "flex flex-col items-start px-4 py-2",
                              selectedDropdownItem === menuItems.UsersTracking
                                ? "selected-menuItem w-full"
                                : "",
                            )}
                            role="menuitem"
                          >
                            User System Tracking
                          </NavLink>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              )}
            </>
          )
        }}
      </Menu>
      <NavLink
        onClick={() => onMenuItemClick("Users")}
        exact
        to={routes.userManagement}
        className={` ${
          currentHeader === "Users"
            ? "adminButton-clicked flex items-center rounded-lg bg-black pb-0 pl-3 pr-3 pt-0 leading-8 text-white"
            : "adminButton flex items-center rounded-lg pb-0 pl-3 pr-3 pt-0 leading-8 text-black"
        } ${mobileMenu ? "w-1/2 justify-start" : "justify-start"} `}
        role="menuitem"
      >
        Users
      </NavLink>
    </>
  )
}

export default AdminMenu
