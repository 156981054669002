import { AnalysisInterface, ResumeInterface } from "@relay/interfaces"
import { apiClientWithAuth, apiEndpoints } from ".."

/**
 * This should function as an upsert (create or update) and goes to the route that updates the resume analysis.
 */
export const postResume = async (
  resume: ResumeInterface,
): Promise<{ analysis: AnalysisInterface; resume: ResumeInterface }> => {
  const res = await apiClientWithAuth.post(apiEndpoints.resumes, resume)
  return res.data
}

export const fetchResume = async (): Promise<ResumeInterface> => {
  const res = await apiClientWithAuth.get(apiEndpoints.resumes)
  return res.data
}
