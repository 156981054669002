import { TriggersInterface } from "@relay/interfaces"
import { TriggersState } from "../reducers/triggersReducer"

export const CLEAR_TRIGGERS = "CLEAR_TRIGGERS" as const
export const STORE_TRIGGERS = "STORE_TRIGGERS" as const

export interface StoreTriggersAction {
  type: typeof STORE_TRIGGERS
  triggers: TriggersState
}

export interface ClearTriggersAction {
  type: typeof CLEAR_TRIGGERS
}
export type TriggersActions = StoreTriggersAction | ClearTriggersAction

export const storeTriggers = (triggers: Partial<TriggersInterface>) => ({
  type: STORE_TRIGGERS,
  triggers,
})

export const clearTriggers = () => ({
  type: CLEAR_TRIGGERS,
})
