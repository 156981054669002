import ReactGA from "react-ga4"
import LogRocket from "logrocket"
// @ts-expect-error: FIX: Could not find a declaration file for module 'logrocket-react'. '/Users/steve/code/v-school/lms/node_modules/logrocket-react/dist/index.js' implicitly has an 'any' type. Try `npm i --save-dev @types/logrocket-react` if it exists or add a new declaration (.d.ts) file containing `declare module 'logrocket-react';`
import setupLogRocketReact from "logrocket-react"

export const initializedIntegrations = {
  ga: false,
  logrocket: false,
}
// @ts-expect-error: FIX: Multiple errors, uncomment to see.
export const initializeIntegrations = (settings, user) => {
  if (!settings || !settings.active) return
  const { uid, email, displayName, phoneNumber, group, cohort, course, roles } =
    user
  console.log(user)

  if (settings.ga.active) {
    ReactGA.initialize(settings.ga.id, {
      gaOptions: {
        userId: uid,
      },
    })
    ReactGA.gtag("set", "user_properties", {
      userId: uid,
      name: displayName,
      email,
    })
    ReactGA.send("pageview")
    initializedIntegrations.ga = true
    console.log("ga is initialized", settings.ga.id, " with user ", uid)
  }
  if (settings.logrocket.active) {
    initializedIntegrations.logrocket = true
    LogRocket.init(settings.logrocket.id)
    setupLogRocketReact(LogRocket)
    LogRocket.identify(uid, {
      name: displayName,
      email,
      phoneNumber,
      group,
      cohort,
      course,
      roles,
    })
    if (settings.ga.active) {
      LogRocket.getSessionURL(function (sessionURL) {
        ReactGA.send({
          hitType: "event",
          eventCategory: "LogRocket",
          eventAction: sessionURL,
        })
      })
    }

    console.log(
      `LogRocket is initialized ${settings.logrocket.id} with user ${uid}`,
    )
  }
}

export const integrationsActions = {
  ga: {
    // @ts-expect-error: FIX: Parameter 'prop' implicitly has an 'any' type.
    send: function send(prop) {
      if (!initializedIntegrations.ga) return
      ReactGA.send(prop)
    },
    event: function event(
      // @ts-expect-error: FIX: Parameter 'category' implicitly has an 'any' type.
      category,
      // @ts-expect-error: FIX: Parameter 'action' implicitly has an 'any' type.
      action,
      // @ts-expect-error: FIX: Parameter 'label' implicitly has an 'any' type.
      label?,
      // @ts-expect-error: FIX: Parameter 'value' implicitly has an 'any' type.
      value?,
      // @ts-expect-error: FIX: Parameter 'nonInteraction' implicitly has an 'any' type.
      nonInteraction?,
      // @ts-expect-error: FIX: Parameter 'transport' implicitly has an 'any' type.
      transport?,
    ) {
      if (!initializedIntegrations.ga) return
      ReactGA.event({
        category,
        action,
        label,
        value,
        nonInteraction,
        transport,
      })
    },
  },
  logrocket: {
    // @ts-expect-error: FIX: Parameter 'event' implicitly has an 'any' type.
    track: function track(event, data = {}) {
      if (!initializedIntegrations.logrocket) return
      LogRocket.track(event, data)
    },
  },
}

// @ts-expect-error: FIX: Multiple errors, uncomment to see.
export const trackEvent = (event, data?) => {
  integrationsActions.ga.event("User", event)
  integrationsActions.logrocket.track(event, data)
}
// @ts-expect-error: FIX: Parameter '_path' implicitly has an 'any' type.
export const trackPage = (_path?) => {
  integrationsActions.ga.send("pageview")
}
