import { ApplicationProfileInterface } from "@relay/interfaces"
import {
  ApplicationProfileActions,
  STORE_APPLICATION_PROFILE,
} from "../actions/applicationProfile"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ApplicationProfileState extends ApplicationProfileInterface {}

export const initialAppProfileState: ApplicationProfileInterface = {
  id: "",
  resumeUrl: "",
  coverLetterUrl: "",
  address: {
    city: "",
    street: "",
    country: "",
    zip: "",
    state: "",
  },
  authorizedToWorkInUS: false,
  coverLetterText: "",
  firstName: "",
  middleName: "",
  lastName: "",
  phoneNumber: "",
  educationLevel: "other",
  email: "",
  jobExperience: [],
  autoRejection: true,
}

export default (
  state: ApplicationProfileState = initialAppProfileState,
  action: ApplicationProfileActions,
): ApplicationProfileState => {
  switch (action.type) {
    case STORE_APPLICATION_PROFILE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
