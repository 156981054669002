import axios from "axios"
import envVars from "../../environments/envConfig"
import { getFirebaseUserToken } from "../../firebase-client"

const buildApiClientWithAuth = () => {
  const instance = axios.create()

  instance.interceptors.request.use(async (config) => {
    const token = await getFirebaseUserToken()

    config.headers.Authorization = token ? `Bearer ${token}` : ""

    return config
  })

  instance.interceptors.response.use(
    (res) => {
      return res
    },
    (err) => {
      // @ts-expect-error: FIX: Multiple errors, uncomment to see.
      function tempAlert(msg, duration) {
        console.error(msg, " Error: ", err)
      }
      tempAlert("Request failed.  If persists, please contact Jason.", 5000)
      return Promise.reject(err)
    },
  )

  return instance
}

export const apiClientWithAuth = buildApiClientWithAuth()

export const apiEndpoints = {
  admin: `${envVars.baseApiUrl}/admin`,
  algolia: `${envVars.baseApiUrl}/jobs/algolia`,
  allApplicationInterviews: `${envVars.baseApiUrl}/interview/application/`,
  allContent: `${envVars.baseApiUrl}/content/all`,
  allGroups: `${envVars.baseApiUrl}/groups/all`,
  allInterviews: `${envVars.baseApiUrl}/interview/all`,
  allSearches: `${envVars.baseApiUrl}/searches/all`,
  analysis: `${envVars.baseApiUrl}/analysis`,
  analyzeText: `${envVars.baseApiUrl}/text/analyze`,
  announcements: `${envVars.baseApiUrl}/announcement`,
  applicationNotes: `${envVars.baseApiUrl}/notes/application/`,
  applicationProfile: `${envVars.baseApiUrl}/applicationProfile`,
  applicationRecruiters: `${envVars.baseApiUrl}/contacts/applications`,
  applications: `${envVars.baseApiUrl}/applications`,
  authorization: `${envVars.baseApiUrl}/authorization`,
  coach: `${envVars.baseApiUrl}/coach`,
  coachNotes: `${envVars.baseApiUrl}/coach-notes`,
  codingProblems: `${envVars.baseApiUrl}/codingChallenges`,
  cohorts: `${envVars.baseApiUrl}/cohorts`,
  content: `${envVars.baseApiUrl}/content`,
  course: `${envVars.baseApiUrl}/course`,
  courseContentActive: `${envVars.baseApiUrl}/course/content/active`,
  courseV2: `${envVars.baseApiUrl}/courseV2`,
  crudNavbar: `${envVars.baseApiUrl}/navbar`,
  crudScheduledSearches: `${envVars.baseApiUrl}/scheduled_searches`,
  dashboard: `${envVars.baseApiUrl}/dashboard`,
  detailedJobsQueue: `${envVars.baseApiUrl}/user/jobs/queue/job`,
  errors: `${envVars.baseApiUrl}/errors`,
  events: `${envVars.baseApiUrl}/events`,
  faqs: `${envVars.baseApiUrl}/faq`,
  feedback: `${envVars.baseApiUrl}/feedback`,
  getCSV: `${envVars.baseApiUrl}/jobs/keyword/export`,
  groups: `${envVars.baseApiUrl}/groups`,
  hub_course: `${envVars.baseApiUrl}/hub_course`,
  hub_courseProgress: `${envVars.baseApiUrl}/hub_courseProgress`,
  hub_learningActivities: `${envVars.baseApiUrl}/hub_learningActivities`,
  hub_programs: `${envVars.baseApiUrl}/hub_programs`,
  hub_studentLearningLogs: `${envVars.baseApiUrl}/hub_studentLearningLogs`,
  integrations: `${envVars.baseApiUrl}/integrations`,
  interactions: `${envVars.baseApiUrl}/interactions`,
  internalInteractions: `${envVars.baseApiUrl}/internal-interactions`,
  interviews: `${envVars.baseApiUrl}/interview`,
  job: `${envVars.baseApiUrl}/job`,
  jobs: `${envVars.baseApiUrl}/jobs`,
  jobsByFiltersSet: `${envVars.baseApiUrl}/jobs/filters`,
  jobsByJobTitle: `${envVars.baseApiUrl}/jobs/title`,
  jobsByKeyword: `${envVars.baseApiUrl}/jobs/keyword`,
  jobsByUserSearch: `${envVars.baseApiUrl}/jobs/userSearch`,
  jobsFromPreferredSearch: `${envVars.baseApiUrl}/jobs/search`,
  jobsQueue: `${envVars.baseApiUrl}/user/jobs/queue`,
  knowledgeBase: `${envVars.baseApiUrl}/knowledge-base/algolia`,
  markdown: `${envVars.baseApiUrl}/markdown`,
  note: `${envVars.baseApiUrl}/note`,
  notes: `${envVars.baseApiUrl}/notes`,
  notifications: `${envVars.baseApiUrl}/notifications`,
  notionPage: `${envVars.baseApiUrl}/notion/page`,
  // notionPage: `https://notion.vschool.workers.dev/v1/page`,
  oauth: `${envVars.baseApiUrl}/oauth`,
  onboarding: `${envVars.baseApiUrl}/onboarding`,
  partnerJobs: `${envVars.baseApiUrl}/partnerJobs`,
  preferences: `${envVars.baseApiUrl}/preferences`,
  preferredJobs: `${envVars.baseApiUrl}/jobs/preferred`,
  publicProfile: `${envVars.baseApiUrl}/publicProfile`,
  recommendedJobs: `${envVars.baseApiUrl}/jobs/recommended`,
  recommendedRecruiters: `${envVars.baseApiUrl}/contacts/recommended`,
  removeJobFromQueue: `${envVars.baseApiUrl}/user/jobs/queue`,
  resumes: `${envVars.baseApiUrl}/resumes`,
  scheduledSearches: `${envVars.baseApiUrl}/scheduled_searches/search`,
  searchInAlgolia: `${envVars.baseApiUrl}/jobs/algolia/search`,
  searches: `${envVars.baseApiUrl}/searches`,
  submissions: `${envVars.baseApiUrl}/submissions`,
  successPlan: `${envVars.baseApiUrl}/success-plan`,
  superAdmin: `${envVars.baseApiUrl}/superAdmin`,
  systemDefault: `${envVars.baseApiUrl}/system/defaults`,
  systemEvents: `${envVars.baseApiUrl}/system-events`,
  tasks: `${envVars.baseApiUrl}/tasks`,
  trackEvents: `${envVars.baseApiUrl}/trackEvents`,
  triggers: `${envVars.baseApiUrl}/triggers`,
  user: `${envVars.baseApiUrl}/users`,
  userAppsAndInterviews: `${envVars.baseApiUrl}/admin/user/info`,
  userData: `${envVars.baseApiUrl}/users/data`,
  userSearches: `${envVars.baseApiUrl}/userSearch`,
  workshops: `${envVars.baseApiUrl}/workshops`,
}
