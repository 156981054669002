import React, { useEffect, useState } from "react"
import { NotificationInterface } from "@relay/interfaces"
// @ts-expect-error: FIX: Could not find a declaration file for module 'react-outside-click-handler'. '/Users/steve/code/v-school/lms/node_modules/react-outside-click-handler/index.js' implicitly has an 'any' type. Try `npm i --save-dev @types/react-outside-click-handler` if it exists or add a new declaration (.d.ts) file containing `declare module 'react-outside-click-handler';`
import OutsideClickHandler from "react-outside-click-handler"

import { fetchNotifications } from "../../../../api-client/apiModules/notifications"
import NotificationsList from "./notificationsList"
import { useSelector } from "react-redux"
import { StoreState } from "../../../../redux/reducers"

interface Props {
  showNotifications: boolean
  isMobile?: boolean
  setShowNotificationsList: (show: boolean) => void
}

const ViewNotifications: React.FunctionComponent<Props> = ({
  showNotifications,
  setShowNotificationsList,
  isMobile = false,
}) => {
  const [notifications, setNotifications] = useState<NotificationInterface[]>(
    [],
  )
  const userIsActive = useSelector((state: StoreState) => state.user.active)

  const [loading, setLoading] = useState(false)

  const getNotifications = async () => {
    try {
      setLoading(true)
      const notifs = await fetchNotifications({ limit: 15, viewed: false })
      setNotifications(notifs)
    } catch (e) {
      console.error("error getting notifications", e)
      alert("There was an error getting Notifications.")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    // Get initial notifications on page load
    if (userIsActive) {
      getNotifications()
    }
  }, [userIsActive])

  useEffect(() => {
    // Fetch fresh notifications to update list when opened (triggers can create notifications on the backend)
    if (showNotifications === true) {
      getNotifications()
    }
  }, [showNotifications])

  const toggleShowNotifications = async () => {
    setShowNotificationsList(!showNotifications)
  }

  // @ts-expect-error: FIX: Parameter 'e' implicitly has an 'any' type.
  const onOutsideClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (showNotifications) toggleShowNotifications()
  }

  // @ts-expect-error: FIX: Parameter 'id' implicitly has an 'any' type.
  const removeNotification = (id) => {
    const copy = notifications.filter((x) => x.id !== id)
    setNotifications(copy)
  }

  const responsiveWrapperClass = isMobile
    ? "flex items-center px-4"
    : "relative flex-shrink-0 z-50"

  const responsiveNotifNumberClass: any = !isMobile
    ? {
        position: "absolute",
        bottom: "10%",
        right: "10%",
        backgroundColor: "red",
      }
    : {
        position: "absolute",
        backgroundColor: "red",
        right: "2.5%",
        bottom: "-27%",
      }

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <div className={responsiveWrapperClass}>
        <button
          onClick={toggleShowNotifications}
          className="icon-secondary shadow-none"
          style={{ border: "none", backgroundColor: "#FFF" }}
        >
          <span className="sr-only">View notifications</span>
          <svg
            className="h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#000"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
            />
          </svg>
        </button>

        <div
          style={responsiveNotifNumberClass}
          className="flex h-4 w-4 items-center justify-center rounded-full"
        >
          <p style={{ fontSize: "10px", color: "white" }}>
            {notifications.length}
          </p>
        </div>

        <NotificationsList
          show={showNotifications}
          notifications={notifications}
          loading={loading}
          removeNotification={removeNotification}
          isMobile={isMobile}
        />
      </div>
    </OutsideClickHandler>
  )
}

export default ViewNotifications
