import React from "react"

import "./notification.module.scss"

/* eslint-disable-next-line */
export interface NotificationProps {}

export function Notification(props: NotificationProps) {
  return (
    <div data-testid="notification" className="hidden">
      <div className="pointer-events-none fixed inset-0 flex items-end justify-center px-4 py-6 sm:items-start sm:justify-end sm:p-6">
        <div className="pointer-events-auto flex w-full max-w-md divide-x divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="flex w-0 flex-1 items-center p-4">
            <div className="w-full">
              <p className="text-sm font-medium text-gray-900">
                Receive notifications
              </p>
              <p className="mt-1 text-sm text-gray-500">
                Notications may include alerts, sounds, and badges.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col divide-y divide-gray-200">
              <div className="flex h-0 flex-1">
                <button className="flex w-full items-center justify-center rounded-none rounded-tr-lg border border-transparent px-4 py-3 text-sm font-medium text-gray-600 hover:text-gray-500 focus:z-10 focus:outline-none focus:ring-2 focus:ring-gray-500">
                  Reply
                </button>
              </div>
              <div className="flex h-0 flex-1">
                <button className="flex w-full items-center justify-center rounded-none rounded-br-lg border border-transparent px-4 py-3 text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500">
                  Don't allow
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notification
