export const routes = {
  settings: "/settings",
  preferences: "/preferences",
  resume: "/resume",
  reportedJobs: "/admin/jobs/reported",
  customTriggers: "/customTriggers",
  applicationsFlow: "/applications/flow",
  tasks: "/tasks",
  yourAccount: "/account",
  jobMarketAnalysisDashboard: "/jobmarket-analysis-dashboard",
  applicationsReport: "/applications-report",
  coachGroupDashboard: "/coach/dashboard",
  coachCohortDashboard: "/coach/dashboard/cohort",
  resumeReport: "/resume-report",
  analysis: "/analysis",
  login: "/login",
  submission: "/submission",
  interaction: "/interaction",
  course: "/course",
  workshops: "/workshops",
  coachCourseDashboard: "/coach/course",
  superAdmin: "/super/admin/dashboard",
  successCoachDashboard: "/success-coach/dashboard",
  successCoachManagerDashboard: "/success-coach-manager/dashboard",
  careerCoachManagerDashboard: "/career-coach-manager/dashboard",
  retentionCoachDashboard: "/retention-coach/dashboard",
  careerCoachDashboard: "/career-coach/dashboard",
  directorOfOutcomesDashboard: "/director-of-outcomes/dashboard",
  educationDashboard: "/education/dashboard",
  userSystemTracking: "/user-system-tracking",
  userManagement: "/user-management",
  linkedInOAuthRedirect: "/linkedin/redirect",
  profile: "/profile",
  profileEdit: "/profile/edit",
  adminOnboardingDashboard: "/admin/onboarding",
  adminProgressLearning: "/admin/progress-learning",
  learningLibraryWorkshops: "/workshops",
} as const
