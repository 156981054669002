import toast from "react-hot-toast"

/**
 * There is not a way to log out a user with linkedin REST API -
 *
 * see https://stackoverflow.com/questions/33149106/how-to-log-the-user-out-when-using-linkedins-rest-api
 * and comment from Auth0 team at https://community.auth0.com/t/i-cant-really-logout-of-my-linkedin-account-via-auth0/16941/7
 *
 * Taken from https://github.com/nvh95/react-linkedin-login-oauth2/issues/29
 * Opens a window to go to logout url and closes it - not the best UX, but cannot find an alternative.
 */
export const signOutOfLinkedin = () => {
  try {
    //This will log out user from linked in profile, but pops up a window and closes it
    const popupWidth = 500
    const popupHeight = 500
    // center the popup window
    const left = window.screen.width / 2 - popupWidth / 2 + 10
    const top = window.screen.height / 2 - popupHeight / 2 + 50
    const win = window.open(
      "https://linkedin.com/m/logout",
      "_blank",
      `toolbar=no,status=no,menubar=no,scrollbars=no,resizable=yes, height=${popupHeight},width=${popupWidth}, left=${left}, top=${top}`,
    )

    if (!win) throw new Error("window is null")

    setTimeout(() => {
      win.close()
      toast.success("You are now Signed out.")
    }, 3000)
  } catch (e) {
    console.error(e)
    toast.error(
      "There was a problem signing out. Contact Site Admin if the problem persists.",
    )
  }
}
